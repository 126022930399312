import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Redux 

import { selectFile } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';

type Props = {
    children?: React.ReactNode;
};


const SelectedDataInfo: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile); 
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 

    const [title, setTitle] = useState(''); 

    // -- 

    useEffect(() => { 

        if (selectedFile) { 

            setTitle(selectedFile.title); 

        } else if (selectedFolder) { 

            setTitle(selectedFolder.title); 

        }; 

    },[selectedFile, selectedFolder]); 

    // -- 

    return (

        <>
            
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                marginRight: 10, 
                maxHeight: 50, 
                left: '18%',
                position: 'absolute', 
            }}>

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        maxHeight: 40,
                        paddingTop: 1, 
                        paddingBottom: 1, 
                        paddingLeft: 2,
                        paddingRight: 2,
                        borderRadius: 4,
                        marginLeft: 2
                    }}
                >

                    { selectedFile && <InsertDriveFileIcon sx={{ color: 'rgb(255,185,0)', fontSize: 20 }} />}
                    { selectedFolder && <FolderIcon sx={{ color: selectedFolder?.relatedPatient ? 'rgb(199, 145, 80)' : 'rgb(80,199,199)', fontSize: 20 }} />}

                    <Box 
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: 2, 
                            fontSize: 12, 
                            maxHeight: 50,
                            width: 500,
                            overflowY: 'hidden' }}>{title}</Box>

                </Box>
                
            </Box>

        </>

    );

};

export default SelectedDataInfo;