import React, { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Components 

import Box from '@mui/material/Box';

type Props = {
    children?: React.ReactNode,
    title: string
};

const Section: React.FC<Props> = ({ children, title }) => {

  const { t } = useTranslation(); 
  const dispatch = useAppDispatch(); 

  return (

    <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        width: '95%',
        marginTop: 5,
        fontSize: 14,
      }}>

      <Box
        sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          fontSize: 16,
          marginBottom: 4, 
        }}
      >{t(title)}</Box>

      <Box
          sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: 2,
            borderRadius: 5,
            backgroundColor: 'rgb(52, 53, 58)',   
          }}
      >
          {children}

      </Box>
    </Box>

  );
};

export default Section;




















