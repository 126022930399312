import React from 'react';
import Box from '@mui/material/Box';

// Components 

import OptionRow from './OptionRow';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import NotesIcon from '@mui/icons-material/Notes';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,

};

const Options: React.FC<Props> = ({ children, isPortraitMode }) => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '5%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
    }}>

        <OptionRow iconColor='rgb(84, 147, 255)' hoverColor='rgba(84, 147, 255, 0.2)' section='info'>
            <AccountBoxIcon />
        </OptionRow>

        <OptionRow iconColor='rgb(34, 148, 6)' hoverColor='rgba(34, 148, 6, 0.2)' section='editPatient'>
            <AppRegistrationIcon />
        </OptionRow>

        <OptionRow iconColor='rgb(80,199,199)' hoverColor='rgba(80,199,199, 0.2)' section='files'>
            <DescriptionIcon />
        </OptionRow>

        <OptionRow iconColor='rgb(188, 45, 207)' hoverColor='rgba(188, 45, 207, 0.2)' section='notes'>
            <SpeakerNotesIcon />
        </OptionRow>

        <OptionRow iconColor='rgb(207, 137, 45)' hoverColor='rgba(207, 137, 45, 0.2)' section='directives'>
            <CrisisAlertIcon />
        </OptionRow>

        <OptionRow iconColor='rgb(45, 207, 196)' hoverColor='rgba(45, 207, 196, 0.2)' section='logs'>
            <BubbleChartOutlinedIcon />
        </OptionRow>

        <OptionRow iconColor='rgb(170, 177, 179)' hoverColor='rgba(170, 177, 179, 0.2)' section='settings'>
            <SettingsIcon />
        </OptionRow>

    </Box>
  );
};

export default Options;