import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from './../../redux/hooks';

// Redux 

import { setClosePopup } from '../../redux/features/general/popup';

type Props = {
    children?: React.ReactNode;
  };


const Popup: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    const closePopup = () => { 

        dispatch(setClosePopup()); 

    }; 


    return (

    <div 

    style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100vw',
        height: '100vh',
        bottom: 0,
        right: 0,
        position: 'absolute', 
        zIndex: 5,
        backgroundColor: 'rgba(56, 58, 64, 0.75)'
    }}>


        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            width: '50%',
            height: '50%',
            borderRadius: 10,
            zIndex: 2,
            backgroundColor: '#313338',
            overflow: 'auto',
            scrollbarWidth: 'none',
        }}>

            {children}

        </div>

        
        <div 
            onClick={closePopup}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            left: 0,
        }}></div>

        <div 
            onClick={closePopup}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            top: 0,
        }}></div>

        <div 
            onClick={closePopup}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            right: 0,
        }}></div>

        <div 
            onClick={closePopup}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            bottom: 0,
        }}></div>


    </div>

    );

};

export default Popup;