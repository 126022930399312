import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setHackerData } from '../../../../redux/features/hacker/data/hackerData';

// Assets 

import dashboardDemo from '../../../../demo/doctor/dashboardDemo';

// Components 

import Box from '@mui/material/Box';
import Overview from './Overview';
import DoctorsChart from './DoctorsChart';
import PatientsChart from './PatientsChart';
import Distribution from './Distribution';
import Table from '../security/table/Table';
import { getDashboardData } from '../../../../routes/hacker/users';


const Doctors: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const data = useAppSelector(state => state.hackerData.data); 

    // -- 

    const handleData = async () => { 

      const res = await getDashboardData(); 

      if (res?.data.status) { 

        dispatch(setHackerData(res.data.data)); 

      }; 

    }; 

    // --

    useEffect(() => { 

      if (!data?.softwareVersion) { 

        handleData(); 

      };

    },[data]); 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: '100%', 
      }}>

        <Overview />

        <DoctorsChart />

        <Distribution />

        <PatientsChart />

      </Box>

  );

};

export default Doctors;