import React from 'react';
import Box from '@mui/material/Box';

// Components 

import PatientsList from './PatientsList';
import CreatePatientButton from './CreatePatientButton';
import OverviewButton from './OverviewButton';

const NavigationColumn: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',  
        width: '20%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
    }}>

      <OverviewButton /> 
      
      <PatientsList /> 

      <CreatePatientButton /> 

    </Box>
  );
};

export default NavigationColumn;