import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';

// Routes

import { removeMetaPatient } from '../../../../../../../routes/doctor/patients';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { resetPatient, setRefreshPatientsList } from '../../../../../../../redux/features/doctor/Dashboard/patients/patient';



const Settings: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const patient = useAppSelector(state => state.patient.patient);

    // -- 

    const handleMetaPatientRemoval = async () => { 

        const res = await removeMetaPatient(patient._id); 

        if (res?.data.status === 200) { 

            dispatch(setNotificationMessage(t('Patient successfully removed'))); 

            dispatch(setRefreshPatientsList(true)); 

            dispatch(resetPatient()); 

        }; 

    }; 

    // -- 

    return (

    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxHeight: '90vh',
            overflow: 'auto',
            alignItems: 'center',
            scrollbarWidth: 'none',
        }}
    >

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                width: '100%', 
                paddingLeft: 10,
                marginTop: 10
            }}
        >
            {`${patient.firstName} ${patient.lastName}`}
        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                width: '100%', 
                paddingLeft: 10,
                marginTop: 5,
                fontSize: 15, 
                color: '#C2C5C8'
            }}
        >
            {t('metaPatientDeletionWarning')}
        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                width: '100%', 
                paddingLeft: 10,
                marginTop: 5,
                fontSize: 15, 
                color: '#C2C5C8'
            }}
        >

            <Button 
                sx={{ 
                    width: 200,
                    backgroundColor: 'rgb(92, 0, 0)',
                    borderColor: 'rgb(92, 0, 0)',
                    color: 'rgb(107, 107, 107)',
                    marginBottom: 2,
                    '&:hover': 
                    { 
                        backgroundColor: 'rgb(135, 0, 0)',
                        color: 'rgb(187, 187, 187)',
                        borderColor: 'rgb(135, 0, 0)'
                    }
                }}
                onClick={() => handleMetaPatientRemoval()}
                variant="outlined">{t('removePatient')}
            </Button>

        </Box>



    </Box>

  );
};

export default Settings;