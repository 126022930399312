import React from 'react';
import Box from '@mui/material/Box';

const BannerHeader: React.FC = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly', 
        alignItems: 'center',
        minHeight: 400,
        width: '100vw',
        fontSize: 30,
        color: 'rgb(217, 217, 217)',
        backgroundColor: '#313338',
      }}
    > Legal Information About Medca

    </Box>
  );
};

export default BannerHeader;