import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Routes

import { getFileExplorerPatientsList, getPatientFolderByPatientId, searchPatientInFileExplorer } from '../../../../../../routes/doctor/files';

// Redux 

import { setPatientName, setRelatedPatient } from '../../../../../../redux/features/doctor/Dashboard/documents/editFile';

// Components 

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import PatientRow from './PatientRow';
import { setSearchSection } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';


const PatientsList: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const patientName = useAppSelector(state => state.editFile.patientName); 

    const [dataList, setDataList] = useState([]) as any; 

    // -- 

    const handlePatientSelection = async (metaPatient: any) => { 

        dispatch(setRelatedPatient(metaPatient)); 
        dispatch(setPatientName(metaPatient.fullName)); 

        const res = await getPatientFolderByPatientId(metaPatient.patientId) as any; 

        if (res.data.status === 200) { 

            dispatch(setSearchSection(res.data.folder?._id)); 

        }; 

    }; 

    // -- 

    const getInitialList = async () => { 

        const res = await getFileExplorerPatientsList() as any; 

        if (res?.data.status === 200) { 

            setDataList(res.data.metaPatients); 

        }; 

    }; 

    // -- 

    const handleSearch = async () => { 

        if (patientName) { 

            const res = await searchPatientInFileExplorer(patientName) as any; 

            if (res.data.status === 200) { 
    
                setDataList(res.data.metaPatients); 
    
            }; 
    
        };

    }; 

    // -- 

    useEffect(() => { 

        if (patientName) { 

            handleSearch(); 

        } else { 

            getInitialList(); 

        }; 

    },[patientName]); 

    // -- 

    return (
            
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column-reverse',
            alignItems: 'center', 
            width: '28%', 
            backgroundColor: 'rgb(43,45,49)',
            borderRadius: 5, 
            bottom: '10%',
            left: '46%', 
            position: 'absolute', 
            fontSize: 12,
        }}>

            { dataList?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none' }}>

                <List dense disablePadding>

                {dataList?.map((item, index) => (

                    <ListItem onClick={() => handlePatientSelection(item)} button key={index} disablePadding>

                        <PatientRow patientId={item.patientId} fullName={item.fullName} />

                    </ListItem>

                ))}

                </List>

            </Box>}

            { dataList?.length === 0 &&         
          
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        height: 40,
                        color: '#C2C5C8'
                    }}>
                        <Box sx={{ marginLeft: 2 }}>No Patients Detected.</Box>

                </Box>}
                    
        </Box>

    );

};

export default PatientsList;