import React from 'react';
import Box from '@mui/material/Box';

const Menu: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '20%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>


    </Box>

  );

};

export default Menu;