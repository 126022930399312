import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Redux 

import { selectFile, setMode } from '../../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetCorrectFile } from '../../../../../../../redux/features/doctor/Dashboard/documents/correctFile';

// Components 

import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { resetFileViewer } from '../../../../../../../redux/features/doctor/Dashboard/documents/fileViewer';


const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 

    // --

    const handleQuit = () => { 

        dispatch(resetCorrectFile()); 
        dispatch(setMode('')); 
        dispatch(resetFileViewer()); 
        dispatch(selectFile(null)); 

    }; 

    // -- 

    return (

        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '100%',
                height: 50, 
            }}>

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                    }}
                >

                </Box>

                <Box
                    onClick={handleQuit}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        justifyContent: 'space-evenly',
                        width: 30,
                        height: 30, 
                        cursor: 'pointer',
                        color: '#C2C5C8',
                        borderRadius: '50%',
                        marginRight: 2, 
                        '&:hover': { backgroundColor: 'rgba(127, 135, 135, 0.2)'},
                    }}
                >
                    <HighlightOffIcon sx={{ '&:hover': { color: 'white' }}}/> 
                </Box>

        </Box>



    );

};

export default Header;