import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import Box from '@mui/material/Box';
import NavigationColumn from './navigation/NavigationColumn';
import Info from './info/Info';
import Options from './options/Options';

const Patient: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isPortraitMode = useMediaQuery('(orientation: portrait)');

  // -- 

  return (

    <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%', 
        bottom: 0,
        position: 'absolute',
        backgroundColor: '#201F1E',
        transition: 'left 0.3s',
    }}>

        <NavigationColumn /> 

        <Info /> 

        <Options /> 

    </Box>
  );
};

export default Patient;