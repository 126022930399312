import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from './../redux/hooks';
import { useTheme } from '../themeContext';

// -- Components 

import Notification from '../components/general/Notification';
import Container from '../components/legal/Container';


const Legal: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const { isLightMode, toggleTheme } = useTheme();

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => { 

    if (isLightMode) {

      toggleTheme(); 

    }; 

  },[isLightMode]); 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'space-evenly', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: 'white'
      }}
    >
        <Container />

        <Notification />

    </Box>
  );
};

export default Legal;