import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { setFilter, setIsFiltersDropdownOpened } from '../../../../../../../../redux/features/doctor/Dashboard/patients/searchPatient';
import { useTranslation } from 'react-i18next';

// Redux 

interface Props { 
    positionX,
    positionY
}; 

const FiltersDropdown: React.FC<Props> = ({ positionX, positionY }) => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const filters = useAppSelector(state => state.searchPatient.filters); 

    // -- 

    const handleFilter = (filter: string) => { 

        if (!filters?.includes(filter)) { 

            dispatch(setFilter(filter)); 

        }; 

        dispatch(setIsFiltersDropdownOpened(false)); 

    }; 

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: 200,
                height: 'auto',
                left: positionX - 300, 
                top: positionY, 
                position: 'absolute',
                backgroundColor: 'rgb(82,84,89)',
                borderRadius: 4,
                fontSize: 15, 
                cursor: 'pointer',
                zIndex: 5, 
            }}
        >

            <Box
                onClick={(e) => handleFilter('firstName')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('firstName')}
            </Box>

            <Box
                onClick={(e) => handleFilter('lastName')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('lastName')}
            </Box>

            {/* <Box
                onClick={(e) => handleFilter('dateOfBirth')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('dateOfBirth')}
            </Box> */}

            <Box
                onClick={(e) => handleFilter('email')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('email')}
            </Box>

            <Box
                onClick={(e) => handleFilter('phone')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('phone')}
            </Box>

            <Box
                onClick={(e) => handleFilter('healthCardNumber')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('healthCardNumber')}
            </Box>

            <Box
                onClick={(e) => handleFilter('patientId')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('patientId')}
            </Box>

            <Box
                onClick={(e) => handleFilter('address')}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 30,
                    padding: 3,
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(92 , 94 , 99)'}
                }}
            >{t('address')}
            </Box>


        </Box>

    );

};

export default FiltersDropdown;