import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';


const BottomSpace: React.FC = () => {

    const { t } = useTranslation();

    const section = useAppSelector(state => state.createPatient.section); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 5,
            minHeight: section === 'confirmation' ? 10: 50, 
            width: '100%',
            fontSize: 15, 
            color: '#C2C5C8', 
        }}></Box>
  );
};

export default BottomSpace;