import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components

import Legend from './Legend';
import List from './List';
import CreateFolder from '../folders/CreateFolder';
import ListInfo from './ListInfo';

const FilesList: React.FC = () => {

    const isOpened = useAppSelector((state) => state.createFolder.isOpened); 

    return (

        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%',
                height: '80%', 
            }}>

            <Legend />

            { isOpened && <CreateFolder />}

            <List />

            <ListInfo /> 
            
        </Box>

    );

};

export default FilesList;