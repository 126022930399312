import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import RateReviewIcon from '@mui/icons-material/RateReview';

type Props = {
    correction?: string, 
    mistake?: string
};

const CorrectionRow: React.FC<Props> = ({ mistake, correction }) => {

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%',
                height: 'auto',
                marginBottom: 2,  
            }}
        >
            { mistake && 
            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '90%',
                    minHeight: 40, 
                    marginBottom: 2, 
                    borderWidth: 1,
                    fontSize: 13,
                    color: '#C2C5C8',
                }}
            >
                <RateReviewIcon sx={{ color: 'rgb(34, 148, 6)', marginRight: 1 }}/> 
                {mistake}
            </Box>}

            {correction && 
            
            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '90%',
                    minHeight: 40, 
                    marginBottom: 2, 
                    padding: 2,  
                    borderRadius: 3, 
                    color: '#C2C5C8',
                    fontSize: 13,
                    backgroundColor: 'rgb(43,45,49)'
                }}
            >
                {correction}
            </Box>}
        
        </Box>

    )

}; 

export default CorrectionRow; 