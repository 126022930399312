import React from 'react';
import Box from '@mui/material/Box';

// Components 

import CategoriesList from './CategoriesList';
import SubmitButton from '../actions/SubmitButton';
import ExitButton from '../actions/ExitButton';
import Warning from './Warning';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ProgressColumn: React.FC<Props> = ({ children, isPortraitMode }) => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '23%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
    }}>

        <CategoriesList />

        <Warning /> 

        <SubmitButton /> 

        <ExitButton />

    </Box>
  );
};

export default ProgressColumn;