import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';

type ThemeContextType = {
  isLightMode: boolean;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLightMode, setIsLightMode] = useState<boolean>(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'light' : true;
  });

  const applyThemeStyles = (isLightMode: boolean) => {
    const root = document.documentElement;
    root.style.transition = 'filter 0.3s';

    if (isLightMode) {
      root.style.filter = 'invert(1) hue-rotate(180deg)';
      localStorage.setItem('theme', 'light');
    } else {
      root.style.filter = 'none';
      localStorage.setItem('theme', 'dark');
    }

    const applyMediaFilter = (element: HTMLElement) => {
      element.style.transition = 'filter 0.3s';
      if (isLightMode) {
        element.style.filter = 'invert(1) hue-rotate(180deg)';
      } else {
        element.style.filter = 'none';
      }
    };

    const mediaElements = document.querySelectorAll('img, video');
    mediaElements.forEach((element) => applyMediaFilter(element as HTMLElement));
    
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            if (node.tagName === 'IMG' || node.tagName === 'VIDEO') {
              applyMediaFilter(node);
            }

            node.querySelectorAll('img, video').forEach((mediaNode) => applyMediaFilter(mediaNode as HTMLElement));
          }
        });
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  };

  useEffect(() => {
    const cleanup = applyThemeStyles(isLightMode);
    return () => cleanup();
  }, [isLightMode]);

  // Initial theme application
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsLightMode(savedTheme === 'light');
    }
  }, []);

  const toggleTheme = () => {
    setIsLightMode((prevMode) => !prevMode);
  };

  return (
    <ThemeContext.Provider value={{ isLightMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};