import React, { useState, useEffect }from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const BackButton: React.FC = () => {

  const navigate = useNavigate(); 

  const section = useAppSelector(state => state.navigation.section);

  // -- 

  const handleNavigation = () => { 

    navigate('/dashboard'); 

  }; 

  // -- 

  return (

    <Box
        onClick={handleNavigation}
        sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute', 
        top: '6vh',
        left: '11vw', 
        padding: 1, 
        cursor: 'pointer', 
        fontSize: 12, 
        color: '#C2C5C8',
        borderRadius: 5, 
        '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.4)' }
    }}>
        <ArrowCircleLeftOutlinedIcon /> 

        <Box sx={{ marginLeft: 2, paddingRight: 1 }}>Back to Dashboard</Box>

    </Box>

  );

};

export default BackButton;