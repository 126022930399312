import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Redux 
import { setResetSupportTicket } from '../../../../redux/features/support/supportTicket';

// Utils 
import getStatusColor from '../../../../util/style/getStatusColor';

const TicketDetails: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch(); 

    const firstName = useAppSelector(state => state.supportTicket.firstName); 
    const lastName = useAppSelector(state => state.supportTicket.lastName); 
    const email = useAppSelector(state => state.supportTicket.email); 
    const ticketId = useAppSelector(state => state.supportTicket.ticketId); 
    const status = useAppSelector(state => state.supportTicket.status); 
    const category = useAppSelector(state => state.supportTicket.category); 
    const description = useAppSelector(state => state.supportTicket.description); 
    const createdAt = useAppSelector(state => state.supportTicket.createdAt); 

    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 

    useEffect(() => { 

        if (!isPopupOpened) { 
            dispatch(setResetSupportTicket()); 
        }; 

    },[isPopupOpened]);

    return (
    <div 
    style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
    }}>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
                marginTop: 10, 
            }}>
                <div>{t('author')}</div>
                <div>{firstName} {lastName}</div>
        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>{t('email')}</div>
                <div>{email}</div>
        </div>

        <div
            style={{ 
                width: '90%',
                height: 1,
                backgroundColor: 'grey',
                marginTop: 10, 
                marginBottom: 15,
            }}></div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>{t('status')}</div>
                <div>
                    <strong style={{ 
                        padding: 5,
                        borderRadius: 7,
                        backgroundColor: getStatusColor(status), 
                    }}>
                        {status}
                    </strong>
                </div>
        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>{t('ticketId')}</div>
                <div>{ticketId}</div>
        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>{t('creationDate')}</div>
                <div>{moment.utc(createdAt).format('LL')}</div>
        </div>

        <div
            style={{ 
                width: '20%',
                marginRight: '70%',
                height: 1,
                backgroundColor: 'grey',
                marginTop: 10, 
                marginBottom: 15,
            }}></div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '90%',
                height: 50,
            }}>
                <div>{t('category')}</div>
                <div style={{ marginLeft: 10 }}>{category}</div>
        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '90%',
                height: 50,
            }}>
                <div>{t('description')}</div>
        </div>

        <div style={{
                flex: 1,
                flexWrap: 'wrap',
                flexFlow: 'column',
                width: '90%',
                overflowWrap: 'normal',
                color: '#C2C5C8',
                fontSize: 12,
                marginTop: 10,
                marginBottom: 10, 
        }}>{description}</div>

        <div
            style={{ 
                width: '90%',
                height: 1,
                backgroundColor: 'grey',
                marginTop: 10, 
                marginBottom: 15,
            }}></div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '90%',
                height: 50,
            }}>
                <div>{t('logs')}</div>
        </div>

        <div style={{
                width: '90%',
                overflowWrap: 'normal',
                color: '#C2C5C8',
                fontSize: 12,
                marginTop: 10,
                marginBottom: 30, 
        }}>{t('noLogsDetected')}</div>
    </div>
    );
};

export default TicketDetails;