import React from 'react';
import Box from '@mui/material/Box';

type Props = {
  number: number;
  previewUrl: string;
  title: string;
};

const Template: React.FC<Props> = ({ number, previewUrl, title }) => {

  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: 150,
        height: 200,
        paddingTop: 2, 
        paddingBottom: 2, 
        paddingLeft: 1,
        paddingRight: 1,
        borderRadius: 5,
        cursor: 'pointer',
        color: '#a6a6a6',
        fontSize: 12,
        '&:hover': { backgroundColor: 'rgba(77, 75, 89,0.3)', color: '#e0e0e0' }
    }}>
      <Box
        component="img"
        sx={{
          height: '100%',
          width: '100%',
          borderRadius: 2,
        }}
        alt="Template Preview"
        src={previewUrl}
      />
      <div style={{ marginTop: 2 }}>{title}</div>
    </Box>
  );
};

export default Template;