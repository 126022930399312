import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setProvider, setPolicyNumber, setGroupNumber, setSection, setCompletedField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputHeader from './items/InputHeader';
import BottomSpace from './items/BottomSpace';
import InputRow from './items/InputRow';
import ConfirmationTitle from './items/ConfirmationTitle';

// Styles 

import iconStyle from './styles/iconStyle';
import inputPropsStyle from './styles/inputPropsStyle';
import textFieldStyle from './styles/textFieldStyle';

// Icons 

import ContactsIcon from '@mui/icons-material/Contacts';
import ApiIcon from '@mui/icons-material/Api';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const InsuranceDetails: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section); 
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);
    const nextSection = useAppSelector((state) => state.createPatient.nextSection);

    const provider = useAppSelector((state) => state.createPatient.provider); 
    const policyNumber = useAppSelector((state) => state.createPatient.policyNumber); 
    const groupNumber = useAppSelector((state) => state.createPatient.groupNumber); 

    const [providerError, setProviderError] = useState(''); 
    const [policyNumberError, setPolicyNumberError] = useState(''); 
    const [groupNumberError, setGroupNumberError] = useState(''); 

    const providerRef = useRef() as any; 
    const policyNumberRef = useRef() as any; 
    const groupNumberRef = useRef() as any; 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'provider') { 

            dispatch(setProvider(input)); 

        } else if (section === 'policyNumber') { 

            dispatch(setPolicyNumber(input)); 

        } else if (section === 'groupNumber') { 

            dispatch(setGroupNumber(input)); 

        };

    };

    const confirmInput = (key: string, field: string) => { 

        if (key == 'Enter') { 

            if (field === 'provider') { 

                policyNumberRef.current.focus(); 
    
            } else if (field === 'policyNumber') { 

                groupNumberRef.current.focus(); 
    
            } else if (field === 'groupNumber') { 
    
                if (section !== 'confirmation') { 

                    dispatch(setSection(nextSection)); 

                };

            };

        }; 

    };

    // -- 

    useEffect(() => { 

        if (erroredFields.length > 0) { 

            setProviderError('');
            setPolicyNumberError('');
            setGroupNumberError('');

            erroredFields.forEach((item: any) => { 

                if (item.field === 'provider') { 

                    setProviderError(item.error); 

                } else if (item.field === 'policyNumber') { 

                    setPolicyNumberError(item.error); 

                } else if (item.field === 'groupNumber') { 

                    setGroupNumberError(item.error); 

                }; 

            });

        }; 

    },[erroredFields]); 


    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: section === 'confirmation' ? 'auto' : '95%',
        }}>

            <InputHeader title={'provider'} /> 

            <InputRow 
                icon={<ContactsIcon sx={iconStyle} /> } 
                iconColor='rgb(207, 137, 45)' 
                value={provider}
                error={providerError}>

                <TextField
                    value={provider}
                    placeholder={String(t('provider'))}
                    onChange={(e) => handleInput(e.target.value, 'provider')}
                    variant="standard"
                    autoComplete='off'
                    autoFocus 
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'provider')}
                    inputRef={providerRef}
                />
            </InputRow>

            <InputHeader title={'policyNumber'} /> 

            <InputRow 
                icon={<ApiIcon sx={iconStyle} /> } 
                iconColor='rgb(45, 153, 207)' 
                value={policyNumber}
                error={policyNumberError}>

                <TextField
                    value={policyNumber}
                    placeholder={String(t('policyNumber'))}
                    onChange={(e) => handleInput(e.target.value, 'policyNumber')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'policyNumber')}
                    inputRef={policyNumberRef}
                />
            </InputRow>

            <InputHeader title={'groupNumber'} /> 

            <InputRow 
                icon={<ConfirmationNumberIcon sx={iconStyle} /> } 
                iconColor='rgb(45, 207, 62)' 
                value={groupNumber}
                error={groupNumberError}>

                <TextField
                    value={groupNumber}
                    placeholder={String(t('groupNumber'))}
                    onChange={(e) => handleInput(e.target.value, 'groupNumber')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'groupNumber')}
                    inputRef={groupNumberRef}
                />
            </InputRow>

            <BottomSpace /> 

        </Box>
    );
};

export default InsuranceDetails;