import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Routes

import { moveFolder } from '../../../../../routes/doctor/files';

// Redux 

import { selectFile, selectFolder, setCurrentFolder, setMode, setRefreshFileExplorer, setSearchSection } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { resetEditFolder, setEditedFolderTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFolder';

// Components 

import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const MoveFolder: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const directory = useAppSelector(state => state.fileExplorer.directory); 
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 
    const currentFolder = useAppSelector(state => state.fileExplorer.currentFolder); 
    const accountInfo = useAppSelector(state => state.meta.accountInfo); 

    const [text, setText] = useState(''); 
    const [titleError, setTitleError] = useState(''); 
    const [path, setPath] = useState(''); 
    const [isTransferAllowed, setIsTransferAllowed] = useState(false); 

    // -- 

    const handleUnSelection = () => { 

        dispatch(setMode(''));
        dispatch(setSearchSection(selectedFolder?.folder));
        dispatch(selectFolder(null));
        dispatch(resetEditFolder()); 

    }; 

    // -- 

    const handleFileTransfer = async () => { 

        const res = await moveFolder(selectedFolder?._id, currentFolder?._id);  

        if (res?.data.status === 200) {

            dispatch(setCurrentFolder(res.data.folder)); 
            dispatch(setRefreshFileExplorer(true)); 
            dispatch(setNotificationMessage(t('folderMoved')));
            dispatch(selectFolder(null)); 
            dispatch(setMode(''));
            dispatch(resetEditFolder());

        } else if (res?.data.status === 401) {

            if (res?.data.error === 'invalidTitle') { 

                setTitleError(String(t('invalidTitle'))); 

            } else if (res?.data.error === 'existingFile') { 

                setTitleError(String(t('existingFile'))); 

            }; 

        };

    }; 

    // -- 

    useEffect(() => { 

        if (currentFolder) { 

            if (currentFolder.uploadedBy != accountInfo._id) { 

                setIsTransferAllowed(false); 

            } else if (currentFolder._id == selectedFolder.folder) { 

                setIsTransferAllowed(false); 

            } else if ((currentFolder.path === 'Recent') || (currentFolder.path === 'Patients') || (currentFolder.path === 'Shared')) { 

                setIsTransferAllowed(false); 

            } else { 

                setIsTransferAllowed(true); 

            }; 

        } else { 

            setIsTransferAllowed(false); 

        }; 

    },[currentFolder]); 

    // -- 

    useEffect(() => { 

        if (text) { 

            dispatch(setEditedFolderTitle(text)); 

        };

    },[text]); 

    // -- 

    useEffect(() => { 

        if (selectedFolder) { 

            setText(selectedFolder.title); 

        }; 

    },[selectedFolder]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 60, 
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '28%',
                    marginLeft: '2%', 
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: 'rgb(43,45,49)'
                }}
            >
                <FolderIcon sx={{ color: 'rgb(80,199,199)', fontSize: 15, marginLeft: 1 }} />

                <Box 
                    sx={{
                        fontSize: 12, 
                        marginLeft: 3
                    }}>
                    {currentFolder?.path}
                </Box>

            </Box>


            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '30%',
                }}
            >
                <FolderIcon sx={{ color: 'rgb(80,199,199)', fontSize: 15, marginLeft: 5 }} />

                <TextField
                    variant="standard"
                    value={text}
                    error={titleError ? true : false}
                    helperText={titleError}
                    InputProps={{
                        style: {fontSize: 12 },
                        disableUnderline: true, 
                    }}
                    onChange={(e) => setText(e.target.value)}
                    sx={{
                        marginLeft: 2,
                        fontSize: 12,
                        width: '80%'
                    }}
                />

            </Box>



            <Box style={{ 
                display: 'flex', 
                flexDirection: 'row-reverse',
                alignItems: 'center',  
                width: '40%',
            }}>

                <Button 
                    onClick={() => handleUnSelection()} 
                    sx={{ 
                        marginLeft: 1,
                        marginRight: 3,
                        backgroundColor: 'transparent',
                        borderColor: 'lightblue',
                        '&:hover': 
                        { 
                            backgroundColor: 'rgb(147, 147, 147)',
                            color: 'rgb(207, 207, 207)',
                            borderColor: 'rgb(147, 147, 147)'
                        }
                    }}
                    variant="contained">{t('cancel')}
                </Button>

            { isTransferAllowed ? 
                <Button onClick={() => handleFileTransfer()} sx={{ marginLeft: 5 }} variant="contained">{t('moveFolder')}</Button>: 
                <Button 
                disabled={true}
                sx={{ 
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                    marginLeft: 5
                }}
                variant="outlined">{t('moveFolder')}</Button>}
                    
                </Box>

        </Box>

    );

};

export default MoveFolder;