import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// Redux 

import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { setClosePopup } from '../../../../../../redux/features/general/popup';
import { setResetCreatePatient } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setSection } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { useTranslation } from 'react-i18next';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ExitProcess: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const cancelPatientCreation = () => { 

        dispatch(setResetCreatePatient()); 

        dispatch(setSection('patient')); 

        dispatch(setClosePopup()); 

    }; 

    const goBackToPatientCreation = () => { 

        dispatch(setClosePopup()); 

    }; 


  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>

        <Box>{t('areYouSure')}</Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%'}}>
        <Button 
          sx={{ 
            width: '75%',
            backgroundColor: 'rgb(92, 0, 0)',
            borderColor: 'rgb(92, 0, 0)',
            color: 'rgb(107, 107, 107)',
            marginBottom: 2,
            '&:hover': 
            { 
                backgroundColor: 'rgb(135, 0, 0)',
                color: 'rgb(187, 187, 187)',
                borderColor: 'rgb(135, 0, 0)'
             }
          }}
          onClick={() => cancelPatientCreation()}
          variant="outlined">{t('exit')}</Button>
                  <Button 
          sx={{ 
            width: '75%',
            backgroundColor: 'rgb(127, 127, 127)',
            borderColor: 'rgb(127, 127, 127)',
            color: 'rgb(177, 177, 177)',
            '&:hover': 
            { 
                backgroundColor: 'rgb(147, 147, 147)',
                color: 'rgb(207, 207, 207)',
                borderColor: 'rgb(147, 147, 147)'
             }
          }}
          onClick={() => goBackToPatientCreation()}
          variant="outlined">{t('back')}</Button>
          </Box>



    </Box>
  );
};

export default ExitProcess;