import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// --- 

export const getMetaPatientInfo = async (patientId: string, doctorId?: string) => {
    try {
        const content = { 
            patientId: patientId,
            doctorId: doctorId, 
        }; 

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patients/list`, content, getConfig());

        return response;

    } catch (error) {
        console.error('Error retrieving meta patient info', error);
    };
}

// ---

export const getMetaPatientsList = async () => {

    try {
        
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/patients`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error retrieving patient list', error);
    };

};

// -- 

export const getPatient = async (patientId : string) => {
    
    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/patients/${patientId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error retrieving the patient info.', error);
    };
};

// -- 

export const getPatientNotes = async (patientId : string) => {
    
    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/notes/${patientId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error retrieving the patient notes.', error);
    };
};

// -- 

export const getPatientDirectives = async (patientId : string) => {
    
    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/directives/${patientId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error retrieving the patient directives.', error);
    };
};

// -- 

export const getDoctorName = async (doctorId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/${doctorId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error retrieving a doctor information', error);
    };
    
};

// -- 

export const getDoctorPatientDocs = async (patientId : string) => {
    try {
        const jwtToken = Cookies.get('jwt');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/documents/${patientId}`,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                }
            }
        );

        if (response.status === 200) { 
            
            const data = response.data.files;
            return data; 
              
        };

    } catch (error) {
        console.error('Error retrieving patient document list', error);
    };

}; 

// -- 

export const createPatient = async (patient : any) => {

    try {

        const content = { 
            patient: patient
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/createPatient`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a patient', error);
    };

}; 

// -- 

export const updatePatient = async (patient : any) => {

    try {

        const content = { 
            patient: patient
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/udpate`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error updating a patient.', error);
    };

}; 

// -- 

export const createPatientNote = async (patientId: any, description: string) => {

    try {

        const content = { 
            patientId: patientId,
            description: description

        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/note/create`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a note', error);
    };

}; 

// -- 

export const editPatientNote = async (patientNoteId: any, description: string) => {

    try {

        const content = { 
            patientNoteId: patientNoteId,
            description: description

        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/note/edit`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error editing a patient note.', error);
    };

}; 

// -- 

export const deletePatientNote = async (patientNoteId: any) => {

    try {

        const content = { 
            patientNoteId: patientNoteId,

        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/note/delete`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error deleting a patient note', error);
    };

}; 

// -- 

export const createPatientDirective = async (patientId: any, description: string) => {

    try {

        const content = { 
            patientId: patientId,
            description: description

        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/create/directive`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a patient', error);
    };

}; 

// -- 

export const removeMetaPatient = async (patientId: string) => { 

    try {

        const content = { 
            patientId: patientId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/metaPatient/remove`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error removing a metapatient relationship.', error);
    };

}; 

// -- 

export const searchPatient = async (queries: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/patients/search`, queries, getConfig());

        return res; 

    } catch (error) {
        console.error('Error searching a patient.', error);
    };

}; 

// -- 

export const verifyMetaPatientRelationship = async (patientId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/metaPatient/verify/${patientId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error searching a patient.', error);
    };

}; 

// -- 

export const requestPatientAccess = async (patientId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/patient/access/request/${patientId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error requesting a patient access.', error);
    };

}; 

