import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

// Redux 

import { setOpenPopup } from '../../../../../../redux/features/general/popup';

const ExitButton: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch(); 

    const section = useAppSelector(state => state.createPatient.section); 

  // -- 

    const handleOpenPopup = () => { 

        dispatch(setOpenPopup()); 
    }; 

  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5,
        height: '5vh',
        width: '100%',
        bottom: 0,
        postion: 'absolute'
    }}>

        <Button 
          sx={{ 
            width: '75%',
            backgroundColor: 'transparent',
            borderColor: 'rgb(107, 107, 107)',
            color: 'rgb(107, 107, 107)',
            '&:hover': 
            { 
                backgroundColor: 'rgb(135, 0, 0)',
                color: 'rgb(187, 187, 187)',
                borderColor: 'rgb(135, 0, 0)'
             }
          }}
          onClick={() => handleOpenPopup()}
          variant="outlined">{t('exit')}</Button>


    </Box>

  );

};

export default ExitButton;