import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { useTranslation } from 'react-i18next';

// Components 

import Options from './options/Options';
import Scan from './scan/Scan';
import Upload from './upload/Upload';
import Success from './success/Success';

const Container: React.FC = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const section = useAppSelector(state => state.scanTemplate.section);  

  return (

    <Box component="main" sx={{ 
        display: 'flex', 
        height: '100vh', 
        width: '100vw',
        right: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        backgroundColor: '#313338',
        color: 'white' }}>

      { section === 'options' && <Options />}
      { section === 'scan' && <Scan />}
      { section === 'upload' && <Upload />}
      { section === 'success' && <Success />}
      
    </Box>

  );
};

export default Container;