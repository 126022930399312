import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './../redux/hooks';

// -- Routes

import { acceptAccessRequest } from '../routes/general/authentication';

// -- Components 

import Box from '@mui/material/Box';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Logo from '../components/general/navBar/Logo';

const AccessRequest: React.FC = () => {

    const { patientAccessRequestId } = useParams<{ patientAccessRequestId: string }>();

    const [isRequestAccepted, setIsRequestAccepted] = useState(false); 

    // -- 

    const handleRequest = async () => { 

        const res = await acceptAccessRequest(patientAccessRequestId); 

        if (res?.data.status === 200) { 

            setIsRequestAccepted(true); 

        }; 

    }; 

    // -- 


    useEffect(() => { 

        if (patientAccessRequestId) { 

            handleRequest(); 

        }; 

    },[patientAccessRequestId]); 

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '100vh', 
                width: '100vw',
                left: 0, 
                overflowX: 'hidden',
                position: 'absolute',
                color: 'white' 
            }}
        >

            { isRequestAccepted && <VerifiedUserIcon sx={{ fontSize: 100, color: 'rgb(45, 207, 62)' }} />}

            <Logo />

        </Box>

  );
};

export default AccessRequest;