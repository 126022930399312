import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Components 

import Box from '@mui/material/Box';
import Navigation from './navigation/Navigation';
import Doctors from './doctors/Doctors';
import Security from './security/Security';

const Data: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const section = useAppSelector(state => state.hackerData.section); 

    return (

    <Box 
        style={{ 
        overflow: 'auto',
        width: '100%',
        scrollbarWidth: 'none'
    }}>

        <Navigation /> 

        { section === 'doctors' && <Doctors />}

        { section === 'security' && <Security />}


    </Box>

  );

};

export default Data;