import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from './../../../redux/hooks';

// Redux 

import { setDashboardSection } from '../../../redux/features/general/navigation';

// Components 

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';


type Props = {
    children?: React.ReactNode;
    key: any,
    item: any, 
    index: number
  };


const SidebarItem: React.FC<Props> = ({ children, item, index }) => {

    const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const section = useAppSelector(state => state.navigation.section); 

    // -- 

    const changeSection = (section: any) => { 

        if (section) { 

            dispatch(setDashboardSection(section)); 

        };

    }; 

    // -- 

    return (

        <Box
          onClick={() => changeSection(item.section)} 
          key={index}
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: '75%', 
            height: item.type === 'item' ? 100 : 10,
            borderRadius: 4,
            cursor: item.type === 'header' ? 'auto' : 'pointer',
            color: section === item.section ? 'rgb(45, 207, 196)' : 'transparent',
          }}
        >
          { item.type === 'item' && 
            <ListItemIcon 
                sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                borderRadius: 4,
                paddingTop: 1,
                paddingBottom: 1,
                marginBottom: 0.3,
                  color: section === item.section ? 'rgb(45, 207, 196)' : item.iconColor,
                '&:hover': { backgroundColor: item.type === 'header' ? 'transparent' : 'rgba(77, 75, 89, 0.3)' }}
                }>
                  <Icon sx={{ fontSize: 35 }}>{item.iconName}</Icon>
            </ListItemIcon>}

          { item.type === 'item' &&
          <Box
            sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
              justifyContent: 'space-evenly', 
              width: '100%', 
              fontSize: 12,
              color: section === item.section ? 'white' : '#C2C5C8'
            }}
          >{t(item.text)}</Box>}

        </Box>

    );

};

export default SidebarItem;