import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setHackerData, setSecurityData } from '../../../../redux/features/hacker/data/hackerData';

// Routes 

import { getSecurityData } from '../../../../routes/hacker/users';

// Components 

import Box from '@mui/material/Box';
import Table from './table/Table';


const Security: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const securityData = useAppSelector(state => state.hackerData.securityData); 

    // -- 

    const handleData = async () => { 

      const res = await getSecurityData() as any;  

      if (res.data.status === 200) { 

        dispatch(setSecurityData(res.data.data)); 

      }; 

    }; 

    // -- 

    useEffect(() => { 

      handleData();

    },[]);

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: '100%', 
      }}>

        <Table />

      </Box>

  );

};

export default Security;