import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux 

import { setTemplatesList, setTemplatesSection } from '../../../../../../redux/features/doctor/Dashboard/templates/templates';

// Routes

import { getTemplates } from '../../../../../../routes/doctor/templates';

// Components 

import Template from './Template';
import TemplateExample from './TemplateExample';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


// Utils 


const List: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const templatesList = useAppSelector(state => state.templates.templatesList); 

    const [templatesExampleList, setTemplatesExampleList] = useState([1, 2, 3, 4]); 

    // --

    const handleNavigation = () => { 

        dispatch(setTemplatesSection('createTemplate'));

    };

    // -- 

    const handleTemplates = async () => { 

        const res = await getTemplates() as any; 

        if (res.data.status === 200) { 

            dispatch(setTemplatesList(res.data.templates)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        handleTemplates(); 

    },[]);

    // -- 

    return (

        <Box style={{ 
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            overflowWrap: 'normal',
            width: '95%',
            height: '86%', 
            borderRadius: 10,
            marginTop: '2%', 
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'width 0.3s',
        }}>


        {(templatesList?.length > 0) && templatesList?.map((item, index) => (
            <Template
                key={index}
                number={index + 1}
                previewUrl={item.previewUrl}
                title={item.title}
            />
        ))}

{(templatesList?.length > 0) && 
    
    <Box 
        onClick={handleNavigation}
    sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: 130,
        height: 200,
        paddingTop: 2, 
        paddingBottom: 2, 
        paddingLeft: 1,
        paddingRight: 1,
        borderRadius: 5,
        marginLeft: 3,
        cursor: 'pointer',
        color: '#a6a6a6',
        fontSize: 12,
    }}>
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                minHeight: '95%',
                width: 130,
                borderStyle: 'dashed',
                borderWidth: 1, 
                borderColor: 'rgba(45, 207, 196, 0.5)',
                borderRadius: 4, 
                marginBottom: 2,
                color: 'rgba(45, 207, 196, 0.7)',
                '&:hover': { borderColor: 'rgba(45, 207, 196, 0.8)', color: 'rgb(45, 207, 196)'}
            }}
        >
            <AddCircleOutlineIcon sx={{ fontSize: 30 }} /> 
        </Box>

        <Box sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '5%'}}>{`Create`}</Box>

    </Box>}

        { templatesList?.length === 0 && 
            templatesExampleList?.map((item, index) => (
            <TemplateExample index={index} number={index + 1}/>
        ))}

            
        </Box>

    );

};

export default List;