import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { selectFile, selectFolder, setIsDropdownOpened, setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetEditFile } from '../../../../../redux/features/doctor/Dashboard/documents/editFile';
import { resetEditFolder } from '../../../../../redux/features/doctor/Dashboard/documents/editFolder';

type Props = {
    children?: React.ReactNode;
};


const ConfirmButton: React.FC<Props> = ({ children }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const unconfirmedSelection = useAppSelector((state) => state.fileExplorer.unconfirmedSelection); 

    // -- 

    const confirmSelection = () => { 

        dispatch(selectFile(unconfirmedSelection)); 

    }; 

    // -- 

    const handleUnSelection = () => { 

        dispatch(setMode(''));
        dispatch(selectFile(null)); 
        dispatch(selectFolder(null));
        dispatch(setIsDropdownOpened(false));
        dispatch(resetEditFile());  
        dispatch(resetEditFolder()); 

    }; 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            height: 30, 
            marginBottom: 2,
            right: '3%',
            position: 'absolute'
        }}>

        { unconfirmedSelection ? 
        <Button onClick={() => confirmSelection()} sx={{ marginLeft: 5 }} variant="contained">{t('confirm')}</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
            marginLeft: 5
          }}
          variant="outlined">{t('confirm')}</Button>}

        <Button 
            onClick={() => handleUnSelection()} 
            sx={{ 
                marginLeft: 1,
                backgroundColor: 'transparent',
                borderColor: 'lightblue',
                '&:hover': 
                { 
                    backgroundColor: 'rgb(147, 147, 147)',
                    color: 'rgb(207, 207, 207)',
                    borderColor: 'rgb(147, 147, 147)'
                }
            }}
            variant="contained">{t('cancel')}</Button>
            
        </Box>

    );

};

export default ConfirmButton;