import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Action from '../../actions/Action';

// Icons 

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

const FileActions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            zIndex: 10,
            right: '25%',
            height: 30, 
            position: 'absolute', 
            backgroundColor: 'rgb(49,51,56)',
        }}>

            <Action title={'correctFile'} iconColor={'rgb(80,199,199)'} action={'correctFile'} type='default' hideLabel>
                <AutoAwesomeIcon /> 
            </Action>

            <Action title={'viewFile'} iconColor={'rgb(201, 117, 42)'} action={'viewFile'} type='default' hideLabel>
                <RemoveRedEyeIcon /> 
            </Action>

            <Action title={'renameFile'} iconColor={'rgb(34, 148, 6)'} action={'renameFile'} type='default' hideLabel>
                <DriveFileRenameOutlineIcon /> 
            </Action>

            <Action title={'moveTo'} iconColor={'rgb(30, 144, 255)'} action={'moveFile'} type='default' hideLabel>
                <DriveFileMoveIcon /> 
            </Action>

            { selectedFile?.relatedPatient &&

            <Action title={'patientInfo'} iconColor={'rgb(127, 52, 235)'} action={'patientInfo'} type='default' hideLabel>
                <AccountCircleIcon /> 
            </Action>}

            <Action title={'deleteFile'} iconColor={'rgb(201, 79, 42)'} action={'deleteFile'} type='default' hideLabel>
                <DeleteIcon /> 
            </Action>

        </Box>

    );

};

export default FileActions;