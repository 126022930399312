import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getBlobHeadersConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    }; 

    return config as any; 

}; 

// --

export const getTemplates = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/templates`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent templates:', error);
    }
};

// -- 

export const createTemplate = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/generate-link`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent templates:', error);
    }
};

// -- 

export const uploadTemplateImages = async (tempId: any, formData: any) => {

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/upload/${tempId}`, formData, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error uploading template images:', error);
    }
};

export const verifyTempLinkStatus = async (tempLinkId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/status/${tempLinkId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error uploading template images:', error);
    }
};