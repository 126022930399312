import React, { useState, useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { resetScanTemplates, setImage, setResetInputValue } from '../../../redux/features/doctor/Dashboard/templates/scanTemplate';
import { setTemplatesSection } from '../../../redux/features/doctor/Dashboard/templates/templates';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import FlipCameraIosRoundedIcon from '@mui/icons-material/FlipCameraIosRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const Scan: React.FC = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [useFrontCamera, setUseFrontCamera] = useState(false);

  // -- 

  const startCamera = async () => {

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: useFrontCamera ? 'user' : 'environment' },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }

  };

  // --

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      stream.getTracks().forEach(track => track.stop());
    }
  };

  // --

  const captureImage = () => {

    if (videoRef.current && canvasRef.current) {

      const context = canvasRef.current.getContext('2d');

      if (context) {

        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0);
        const imageData = canvasRef.current.toDataURL('image/png');

        dispatch(setImage(imageData)); 
        dispatch(setTemplatesSection('upload')); 

      }; 

    }; 
  };

  // -- 

  const handleQuit = () => {

    dispatch(resetScanTemplates()); 

  }; 

  // --

  useEffect(() => { 

    startCamera(); 

  },[]);

  // --

  return (

    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection : 'column',
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw',
        right: 0, 
        overflowX: 'hidden',
        overflowY: 'hidden', 
        position: 'absolute',
        color: 'white' }}>


          <Box
            sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '100%', 
            height: '70%',
          }}>
            <video ref={videoRef} style={{ width: '80%', height: '90%', marginBottom: 2 }} />
          </Box>

          <Box
            sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            marginTop: 6,
            width: '100%', 
            height: '30%',
        }}>

            <Box
              onClick={captureImage}
                sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                color: '#C2C5C8', 
                padding: 2
            }}
            >
                <CameraAltRoundedIcon sx={{ fontSize: 30, color: 'rgb(45, 207, 196)', marginBottom: 1 }} /> 
                <Box>{t('takePicture')}</Box>
            </Box>

            <Box
                onClick={() => setUseFrontCamera(!useFrontCamera)}
                sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                color: '#C2C5C8', 
                padding: 2
            }}
            >
                <FlipCameraIosRoundedIcon sx={{ fontSize: 30, color: 'rgb(45, 207, 62)', marginBottom: 1 }} /> 
                <Box>{t('flipCamera')}</Box>
            </Box>

        </Box>

        <Box 
            onClick={handleQuit} 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: 50,
            height: 50, 
            top: 10, 
            right: 10,
            cursor: 'pointer',
            position: 'absolute', 
            color: 'white'
        }}>
            <HighlightOffIcon sx={{ fontSize: 30 }} /> 
        </Box>

        <canvas ref={canvasRef} style={{ display: 'none' }} />
    
    </Box>

  );
};

export default Scan;