import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setCategory } from '../../../../redux/features/support/supportEmail';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

const Category: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch(); 
  const category = useAppSelector(state => state.supportEmail.category);

  // Dispatch the description change directly to Redux
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCategory(e.target.value));
  };

  return (
    <Box sx={{ 
      marginTop: '3vh',
      marginBottom: '3vh',
    }}>
      <TextField
        sx={{
          width: '50%',
          backgroundColor: '#27292b'
        }}
        onChange={handleChange}
        id="outlined-multiline-static"
        label={t('category')}
        multiline
        value={category}
        rows={1}
      />
    </Box>
  );
};

export default Category;