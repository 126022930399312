import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import FoldersList from './FoldersList';
import FileViewerOptions from '../actions/fileViewer/FileViewerOptions';

const NavigationColumn: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const mode = useAppSelector(state => state.fileExplorer.mode); 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: isSmallScreen ? '60px' : '15%',
            height: '95%', 
            borderRadius: 10,
            backgroundColor: '#313338',
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'width 0.3s',
        }}>

            { mode !== 'viewFile' && <FoldersList />}

            { mode === 'viewFile' && <FileViewerOptions />}
            
        </Box>

    );

};

export default NavigationColumn;