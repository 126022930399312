import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../../redux/hooks';

// Components 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';


type Props = {
    _id: string, 
    fullName?: React.ReactNode,
};

const PatientRow: React.FC<Props> = ({ _id, fullName }) => {

    const patient = useAppSelector(state => state.patient.patient) as any; 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 40,
            color: '#C2C5C8',
            backgroundColor: patient?._id === _id ? '#4D4B59' : 'transparent',
            '&:hover': { backgroundColor: patient?._id === _id ? '#4D4B59' : 'rgba(77, 75, 89, 0.5)' }
        }}>

            <AccountCircleIcon sx={{ color: 'rgb(80,199,199)', fontSize: 25, marginLeft: 2 }} />

            <Box sx={{ fontSize: 14, marginLeft: 2 }}>{fullName}</Box>

        </Box>
  );
};

export default PatientRow;