import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

//  Routes 

import { getDoctorRecentFiles, getFolderData, getPatientsFolder, getPatientSharedDoctorsFolder, getPatientSharedFilesByDoctor, searchFileExplorer, serveFile } from '../../../../../routes/doctor/files';

// Redux 

import { setCurrentFolder, setFolderData, selectFile, selectFolder, setLastInternalFileIndex,  setRefreshFileExplorer, setIsDropdownOpened, setMouseInfo, setUnconfirmedDraggedFile, setMode, setSearchSection } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setBlobFileUrl, setFileExtension, setFileName } from '../../../../../redux/features/doctor/Dashboard/documents/fileViewer';

// Assets 

import protectedPaths from '../folders/protectedPaths';

// Components 

import FolderDataRow from './FolderDataRow';

import { default as MuiList } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { resetEditFolder } from '../../../../../redux/features/doctor/Dashboard/documents/editFolder';
import { resetEditFile, setEditedFile } from '../../../../../redux/features/doctor/Dashboard/documents/editFile';
import { getMetaPatientInfo } from '../../../../../routes/doctor/patients';

const List: React.FC = () => {
    
    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const folderData = useAppSelector((state) => state.fileExplorer.folderData); 
    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 
    const directory = useAppSelector((state) => state.fileExplorer.directory); 
    const searchQuery  = useAppSelector((state) => state.fileExplorer.searchQuery); 
    const refreshFileExplorer = useAppSelector((state) => state.fileExplorer.refreshFileExplorer); 
    const isOpened = useAppSelector((state) => state.createFolder.isOpened); 
    const isDropdownOpened = useAppSelector((state) => state.fileExplorer.isDropdownOpened); 
    const selectedFolder = useAppSelector((state) => state.fileExplorer.selectedFolder);
    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile); 
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 

    const mode = useAppSelector((state) => state.fileExplorer.mode); 
    const file = useAppSelector((state) => state.uploadFile.file); 

    // -- 

    const handleSearch = async () => { 

        const res = await searchFileExplorer(directory[directory.length - 1], searchQuery) as any; 

        if (res?.data.status === '200') { 
    
            dispatch(setFolderData(res.data.dataList));
            dispatch(setLastInternalFileIndex(res.data.lastInternalDataIndex)); 
    
        }; 

    }; 

    // -- 

    const handleFileSelection = async (e: any, item: any) => { 

        if (e.detail === 2) { 

            if (mode === 'moveFile' || mode === 'moveFolder') { 

                if (item.type === 'folder') { 

                    dispatch(setCurrentFolder(item._doc)); 

                    return; 

                }; 

                return; 

            }; 

            if (item.type === 'file') { 

                dispatch(selectFile(item._doc)); 

                const res = await serveFile(item._doc._id) as any; 
    
                const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
                dispatch(setBlobFileUrl(URL.createObjectURL(fileBlob))); 
                dispatch(setFileExtension(item._doc.fileUrl.split('.').pop() || 'unknown')); 
                dispatch(setFileName(item._doc.fileUrl.split('-').slice(1).join('-') || 'file')); 
                dispatch(setMode('viewFile')); 

            } else if (item.type === 'folder') { 

                dispatch(setCurrentFolder(item._doc)); 

            } else if (item.type === 'rootSharedFolder') { 

                dispatch(setSearchSection('rootSharedFolder')); 

                dispatch(setMode('sharedFolder'));

            } else if (item.type === 'rootSharedDoctorFolder') { 

                const res = await getPatientSharedFilesByDoctor(item._doc._id); 
                
                if (res?.data.status === 200) {

                    dispatch(setFolderData(res.data.dataList));
                    dispatch(setCurrentFolder(res.data.folder._doc)); 
                };

            } else if (item.type === 'sharedFolder') { 

                dispatch(setCurrentFolder(item._doc)); 
                dispatch(setMode('sharedFolder')); 

            } else if (item.type === 'sharedFile') { 

                const res = await serveFile(item._doc._id) as any; 
    
                const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
    
                dispatch(setBlobFileUrl(URL.createObjectURL(fileBlob))); 
                dispatch(setFileExtension(item._doc.fileUrl.split('.').pop() || 'unknown')); 
                dispatch(setFileName(item._doc.fileUrl.split('-').slice(1).join('-') || 'file')); 
                dispatch(setMode('viewFile')); 

            }; 

            dispatch(selectFolder(null)); 

        } else if (e.detail === 1) { 

            if (file || (mode === 'editFile') || (mode === 'editFolder') || (mode === 'moveFile') || (mode === 'moveFolder')) { 

                return; 

            }; 

            if (item.type === 'file') { 

                dispatch(selectFolder(null)); 
                dispatch(selectFile(item._doc)); 

            } else if (item.type === 'folder') { 

                if (protectedPaths.includes(item._doc.path)) { 

                    dispatch(selectFolder(null)); 

                } else { 

                    dispatch(selectFile(null)); 
                    dispatch(selectFolder(item._doc)); 

                }; 

            }; 

            if ((selectedFile && (item._doc._id !== selectedFile?._id)) || (selectedFolder && (item._doc._id !== selectedFolder?._id))) { 

                dispatch(resetEditFile()); 
                dispatch(resetEditFolder()); 

            }; 

        };  

        if ((item.type !== 'sharedFolder') && (mode === 'sharedFolder')) { 

            dispatch(setMode(''));
        }; 

        dispatch(setIsDropdownOpened(false)); 

    }; 

    // -- 

    const handleSpecialOptions = (e: any, item: any) => { 

        if (e.button == 2) { 

            if ((mode === 'editFile') || (mode === 'moveFile')) {
                return; 
            }; 

            if (item.type === 'file') { 

                dispatch(selectFolder(null)); 
                dispatch(selectFile(item._doc)); 

            } else if (item.type === 'folder' && (!protectedPaths.includes(item._doc.path))) { 

                dispatch(selectFile(null)); 
                dispatch(selectFolder(item._doc)); 

            }; 

            if (isDropdownOpened && (selectedFile?._id === item._doc._id || selectedFolder?._id === item._doc._id)) { 

                dispatch(setIsDropdownOpened(false)); 

            } else { 

                dispatch(setIsDropdownOpened(true)); 

                dispatch(setMouseInfo({ clientX: e.clientX, clientY: e.clientY }));

            };

        }; 
        

        // -- 

        if (item.type === 'file') { 

            dispatch(setUnconfirmedDraggedFile(item._doc)); 

        }; 


    }; 

    // -- 

    const handleKeyboardOptions = (e: any, item: any) => { 

        if (e.key == ' ') { 

            if (selectedFile || selectedFolder) { 

                dispatch(selectFile(null)); 
                dispatch(selectFolder(null)); 

            }; 

        }; 

    }; 

    // --

    const getList = async () => { 

        if (searchSection === 'recent') { 

            const res = await getDoctorRecentFiles() as any; 

            if (res?.data.status === '200') { 

                dispatch(setFolderData(res.data.dataList));
                dispatch(setCurrentFolder(res.data.folder)); 
        
            }; 

        } else if (searchSection === 'rootSharedFolder' || searchSection === 'shared') {

            if (searchSection === 'shared') { 
                return; 
            }; 
            
            const res = await getPatientSharedDoctorsFolder(currentFolder.relatedPatient); 

            if (res?.data.status === 200) { 

                dispatch(setFolderData(res.data.dataList));
                dispatch(setCurrentFolder(res.data.folder._doc)); 

            }; 

        } else if (searchSection === 'rootSharedDoctorFolder') { 

            const res = await getMetaPatientInfo(currentFolder.relatedPatient, currentFolder.uploadedBy) as any; 

            if (res.data.status == 200) { 

                const result = await getPatientSharedFilesByDoctor(res.data.metaPatient._id) as any;
                
                if (result?.data.status === 200) {
    
                    dispatch(setFolderData(result.data.dataList));
                    dispatch(setCurrentFolder(result.data.folder._doc)); 
                };

            }; 
            
        } else { 

            const res = await getFolderData(searchSection); 

            if (res?.data.status === '200') { 

                dispatch(setFolderData(res.data.dataList));
                dispatch(setCurrentFolder(res.data.folder)); 

            }; 

        }; 

        dispatch(setRefreshFileExplorer(false)); 

    };

    // -- 

    useEffect(() => { 

        getList(); 

    },[searchSection]);

    // -- 

    useEffect(() => { 

        if (refreshFileExplorer) { 

            getList(); 

        }; 

    },[refreshFileExplorer]); 

    // -- 

    useEffect(() => { 

        if (searchQuery) { 

            handleSearch(); 

        } else { 

            getList(); 

        }; 

    },[searchQuery]); 

    // --

    return (

        <>

        { folderData?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                marginTop: 1, 
                scrollbarWidth: 'none'
                }}>
        
                <MuiList dense disablePadding>
        
                {folderData?.map((item, index) => (
        
                    <ListItem 
                        onClick={(e) => handleFileSelection(e, item)} 
                        onMouseDown={(e) => handleSpecialOptions(e, item)} 
                        onKeyDown={(e) => handleKeyboardOptions(e, item)}
                        button 
                        key={index} 
                        disablePadding>

                        <FolderDataRow 
                            index={index}
                            _id={item._doc._id}
                            title={item._doc.title}
                            path={item._doc.path}
                            createdAt={item._doc.createdAt}
                            updatedAt={item._doc.updatedAt}
                            type={item.type}
                            searchQuery={item.searchQuery}
                        />
       
                    </ListItem>

                ))}
        
                </MuiList>
        
            </Box>}

            {(folderData?.length === 0 && !isOpened) &&   
          
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '100%',
                        height: '100%',
                    }}>
                
                    <Box
                        sx={{
                            width: 252,
                            height: 214, 
                            marginTop: '5%',
                        }}
                        component="img"
                        alt="Solutions Medca Inc."
                        src={require('../../../../../assets/logos/empty_list.png')}
                    />

                    <Box sx={{ 
                        color: '#C2C5C8',
                        fontSize: 12,
                        marginBottom: '20%',
                    }}>{t('emptyFolder')}</Box>

                </Box>}

          </>

    );

};

export default List;