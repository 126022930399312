import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

import { resetPatientNote, setIsRefreshNotes, setPatientNoteDescription } from '../../../../../../../redux/features/doctor/Dashboard/patients/patientNote';
import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';

// Routes 

import { createPatientDirective, createPatientNote } from '../../../../../../../routes/doctor/patients';

// Components 

import Box from '@mui/material/Box';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { resetPatientDirective, setIsRefreshDirectives, setPatientDirective } from '../../../../../../../redux/features/doctor/Dashboard/patients/patientDirective';

const CreateDirective: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const description = useAppSelector(state => state.patientDirective.description);
    const patient = useAppSelector(state => state.patient.patient); 

    const [text, setText] = useState(''); 

    // -- 

    const handleCreateDirective = async () => { 

        if (description && patient) { 

            const res = await createPatientDirective(patient._id, description); 

            if (res?.data.status === 200) { 

                dispatch(setNotificationMessage(t('patientDirectiveCreated'))); 
                dispatch(setIsRefreshDirectives(true));
                dispatch(resetPatientDirective()); 

            }; 

        }; 

    }; 

    // -- 

    const handleEnterKey = async (e: any) => { 

        if (e.key === 'Enter' && e.ctrlKey) { 

            handleCreateDirective(); 

        }; 

    }; 


    // -- 

    useEffect(() => { 

        dispatch(setPatientDirective(text)); 

    },[text]);

    return (

    <Box
        sx={{
            display: 'flex', 
            flexDirection: 'column',
            width: '100%',
            padding: 2,
            borderRadius: 5,
            marginBottom: 3,
            backgroundColor: 'rgb(52, 53, 58)',   
        }}
    >
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between', 
                width: '100%',
                height: 40, 
                marginBottom: 2
            }}
        >
            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <AddCircleOutlineIcon sx={{ color: 'rgb(34, 148, 6)' }} /> 
                <Box sx={{ marginLeft: 2 }}>By Me</Box>
            </Box>

            <Box sx={{ color: '#C2C5C8', marginRight: 2 }}>Today</Box>

        </Box>

        <TextField
            value={description}
            placeholder={String(t('addADirective'))}
            onChange={(e) => setText(e.target.value)}
            variant="standard"
            autoFocus
            multiline
            rows={5}
            autoComplete='off'
            InputProps={{
                style: {fontSize: 15, height: '100%' },
                disableUnderline: true, 
            }}
            sx={{
                marginLeft: 2,
                fontSize: 15,
                width: '100%',
            }}
            onKeyDown={(e) => handleEnterKey(e)}
        />

        { description && 
            <Button 
            onClick={() => handleCreateDirective()}
            sx={{ width: '20%', backgroundColor: 'rgba(59, 87, 196, 0.5)' }} 
            variant="contained">{t('save')}
            </Button>}

    
    </Box>

  );
};

export default CreateDirective;