import React, { useState, useEffect } from 'react'; 
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import TemplatesList from './list/TemplatesList';
import CreateTemplate from './createTemplate/CreateTemplate';
import { verifyTempLinkStatus } from '../../../../routes/doctor/templates';
import { setNotificationMessage } from '../../../../redux/features/general/notification';
import { setRefreshTemplatesList, setTemplatesSection, setTempLinkId } from '../../../../redux/features/doctor/Dashboard/templates/templates';
import { useTranslation } from 'react-i18next';

const Templates: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const { t } = useTranslation(); 

  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '5vw';

  const section = useAppSelector(state => state.templates.section); 
  const tempLinkId = useAppSelector(state => state.templates.tempLinkId); 

  const [timeLeft, setTimeLeft] = useState(0); 


  // -- 

  const handleTemplateStatusValidation = async () => { 

    const res = await verifyTempLinkStatus(tempLinkId) as any; 

    if (res.data.status === 200) { 

        dispatch(setRefreshTemplatesList(true)); 
        dispatch(setNotificationMessage(t('templateCreated')));
        dispatch(setTempLinkId('')); 
        dispatch(setTemplatesSection('list')); 
        setTimeLeft(0); 

    } else if (res.data.status === 202) { 

        setTimeLeft(3); 

    }; 

  }; 


  // -- 

  useEffect(()=>{

      let myInterval = setInterval(() => {

          if (timeLeft > 0) {

              setTimeLeft(timeLeft - 1);

          }; 

          if (timeLeft > 1) { 

              handleTemplateStatusValidation(); 

              setTimeLeft(4); 

          }; 

          if (timeLeft === 0) {

              if (timeLeft === 0) {
                  clearInterval(myInterval)
              };
          }; 

      }, 1000); 

      return ()=> {
          clearInterval(myInterval);
      };

  },[timeLeft]);

  // --
  
  useEffect(() => { 

    if (tempLinkId) { 

      setTimeLeft(3); 

    } else { 

      setTimeLeft(0); 

    }; 

  },[tempLinkId])

  // --

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '95vw',
      height: '95vh', 
      left: leftOffset,
      bottom: 0,
      position: 'absolute',
      backgroundColor: '#201F1E',
      transition: 'left 0.3s',
    }}>

      { section === 'list' && <TemplatesList />}

      { section === 'createTemplate' && <CreateTemplate />} 

    </Box>
  );
};

export default Templates;