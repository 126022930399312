import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface PatientState {
  section: string, 
  infoSection: string, 
  patient: any,
  emergencyContacts: any, 
  patientIdFromFileExplorer: string, 
  refreshPatientsList: boolean, 
};

// ---------------

const initialState: PatientState = {
  section: 'patient', 
  infoSection: 'overview', 
  patient: null,
  emergencyContacts: null,
  patientIdFromFileExplorer: '',
  refreshPatientsList: false, 
};

// ---------------

export const patientSlice = createSlice({

  name: 'patient',

  initialState,

  reducers: {

    setSection: (state, action: PayloadAction<any>) => {

      state.section = action.payload; 

    },
    setInfoSection: (state, action: PayloadAction<any>) => {

      state.infoSection = action.payload; 

    },
    setPatient: (state, action: PayloadAction<any>) => {

      state.patient = action.payload; 

    },
    setEmergencyContacts: (state, action: PayloadAction<any>) => {

      state.emergencyContacts = action.payload; 

    },
    setPatientIdFromFileExplorer: (state, action: PayloadAction<any>) => {

      state.patientIdFromFileExplorer = action.payload; 

    },
    setRefreshPatientsList: (state, action: PayloadAction<boolean>) => {

      state.refreshPatientsList = action.payload; // auto-reset state. 

    },
    resetPatient: (state) => { 
        
      state.section =  'patient';  
      state.infoSection = 'overview'; 
      state.patient = null;
      state.emergencyContacts = null; 
      state.patientIdFromFileExplorer = ''; 

    } 
  },

}); 

// ---------------

export const { 
  setSection, 
  setInfoSection, 
  setPatient, 
  setEmergencyContacts, 
  setPatientIdFromFileExplorer, 
  setRefreshPatientsList, 
  resetPatient, 
 } = patientSlice.actions;

export default patientSlice.reducer;