import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components 

import Box from '@mui/material/Box';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

interface Props { 
    doctorId: string, 
    description: string, 
    updatedAt: string
}; 

const Directive: React.FC<Props> = ({ doctorId, description, updatedAt }) => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const patient = useAppSelector(state => state.patient.patient); 

    return (

    <Box
        sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: 2,
            marginBottom: 2,
            borderRadius: 5,
            backgroundColor: 'rgb(52, 53, 58)',   
            fontSize: 15
        }}
    >
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between', 
                width: '100%',
                height: 40, 
                marginBottom: 2
            }}
        >
            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: '#C2C5C8',
                }}
            >
                <CrisisAlertIcon sx={{ color: 'rgb(207, 137, 45)' }} /> 
                <Box sx={{ marginLeft: 2 }}>By {doctorId}</Box>
            </Box>

            <Box sx={{ color: '#C2C5C8', marginRight: 2 }}>{moment.utc(updatedAt).format('LLL')}</Box>

        </Box>

        <Box
            sx={{ 
                width: '100%',
                textAlign: 'left',
                color: '#C2C5C8',
                fontSize: 15
            }}
        >{description}</Box>

    
    </Box>

  );
};

export default Directive;