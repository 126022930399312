import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Routes

import { attachFileToPatient, searchPatientInFileExplorer } from '../../../../../routes/doctor/files';

// Redux 

import { selectFile, setMode, setRefreshFileExplorer, setSearchSection } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { resetEditFile, setPatientName, setRelatedPatient, setEditedFileTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFile';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PatientsList from './patientOption/PatientsList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const RelatedPatientOption: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const directory = useAppSelector(state => state.fileExplorer.directory); 
    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 

    const relatedPatient = useAppSelector(state => state.editFile.relatedPatient);  
    const patientName = useAppSelector(state => state.editFile.patientName); 
    const editedFileTitle = useAppSelector(state => state.editFile.title); 

    const [text, setText] = useState(''); 
    const [titleText, setTitleText] = useState(''); 

    // -- 

    const handleUnSelection = () => { 

        dispatch(setSearchSection(selectedFile.folder)); 
        dispatch(setMode('')); 
        dispatch(selectFile(null)); 
        dispatch(resetEditFile()); 

    }; 

    // -- 

    const unselectPatient = () => { 

        dispatch(setPatientName('')); 
        dispatch(setRelatedPatient(null));

        dispatch(setSearchSection(selectedFile.folder)); 

    }; 

    // -- 

    const handleSelection = async () => { 

        const res = await attachFileToPatient(selectedFile._id, relatedPatient.patientId, editedFileTitle); 

        if (res?.data.status === 200) { 

            dispatch(setNotificationMessage(t('fileAttachedToPatient'))); 

            dispatch(setMode('')); 
            dispatch(setRefreshFileExplorer(true)); 
            dispatch(selectFile(null)); 
            dispatch(resetEditFile());     

        } else if (res?.data.status === 401) { 

            dispatch(setNotificationMessage(t(res?.data.error))); 

        }; 

    }; 

    // -- 

    const handleInputOptions = (e: any) => { 

        if (e.key === 'Backspace') { 

            unselectPatient(); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        dispatch(setPatientName(text)); 

    }, [text]); 

    // --

    useEffect(() => { 

        if (titleText) { 

            dispatch(setEditedFileTitle(titleText)); 

        } else { 

            dispatch(setEditedFileTitle(selectedFile.title)); 
            
        }; 

    },[titleText]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 60, 
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '28%',
                    marginLeft: '2%', 
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: 'rgb(43,45,49)'
                }}
            >
                <InsertDriveFileIcon sx={{ color: 'rgb(255,185,0)', fontSize: 15, marginLeft: 1 }} />

                <TextField
                    id="file-title"
                    variant="standard"
                    value={editedFileTitle}
                    InputProps={{
                        style: {fontSize: 12 },
                        disableUnderline: true, 
                    }}
                    onChange={(e) => setTitleText(e.target.value)}
                    sx={{
                        marginLeft: 3,
                        fontSize: 12,
                        width: '80%'
                    }}
                />

            </Box>


            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '38%',
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: 'rgb(43,45,49)',
                    marginLeft: '2%',
                }}
            >
                <AccountCircleIcon sx={{ color: 'rgb(175, 101, 230)', fontSize: 20, marginLeft: 2 }} />

                <TextField
                    id="upload-file"
                    variant="standard"
                    value={patientName}
                    autoFocus
                    InputProps={{
                        style: {fontSize: 12 },
                        disableUnderline: true, 
                    }}
                    onChange={(e) => setText(e.target.value)}
                    sx={{
                        marginLeft: 2,
                        fontSize: 12,
                        width: '80%'
                    }}
                    onKeyDown={(e) => handleInputOptions(e)}
                />

                <Box sx={{ fontSize: 12 }}>{relatedPatient?.patientId}</Box>

                { relatedPatient && 
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        justifyContent: 'space-evenly', 
                        color: '#C2C5C8',
                        marginLeft: 2, 
                        cursor: 'pointer',
                        '&:hover': { color: 'red'}
                    }} 
                    onClick={() => unselectPatient()}>
                    <HighlightOffIcon /> 
                </Box>}

            </Box>



            <Box style={{ 
                display: 'flex', 
                flexDirection: 'row-reverse',
                alignItems: 'center',  
                width: '30%',
            }}>

                <Button 
                    onClick={() => handleUnSelection()} 
                    sx={{ 
                        marginLeft: 1,
                        marginRight: 3,
                        backgroundColor: 'transparent',
                        borderColor: 'lightblue',
                        '&:hover': 
                        { 
                            backgroundColor: 'rgb(147, 147, 147)',
                            color: 'rgb(207, 207, 207)',
                            borderColor: 'rgb(147, 147, 147)'
                        }
                    }}
                    variant="contained">{t('cancel')}
                </Button>

            { (relatedPatient) ? 
                <Button onClick={() => handleSelection()} sx={{ marginLeft: 5 }} variant="contained">{t('confirm')}</Button>: 
                <Button 
                disabled={true}
                sx={{ 
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                    marginLeft: 5
                }}
                variant="outlined">{t('confirm')}</Button>}
                    
                </Box>

                <PatientsList />

        </Box>

    );

};

export default RelatedPatientOption;