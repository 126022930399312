import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Routes

import { uploadFile } from '../../../../../routes/doctor/files';

// Redux 

import { selectFile, setCurrentFolder, setMode, setRefreshFileExplorer } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { resetUploadFile, setIsFileUploaded, setTitle } from '../../../../../redux/features/doctor/Dashboard/documents/uploadFile';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';


type Props = {
    children?: React.ReactNode;
};


const FileUpload: React.FC<Props> = ({ children }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const directory = useAppSelector(state => state.fileExplorer.directory); 
    const currentFolder = useAppSelector(state => state.fileExplorer.currentFolder); 

    const file = useAppSelector(state => state.uploadFile.file); 
    const title = useAppSelector(state => state.uploadFile.title); 

    const [text, setText] = useState(''); 
    const [titleError, setTitleError] = useState(''); 
    const [path, setPath] = useState(''); 
    const [isUploading, setIsUploading] = useState(false); 

    // -- 

    const handleUnSelection = () => { 

        dispatch(setMode(''));
        dispatch(resetUploadFile()); 

    }; 

    // -- 

    const handleUpload = async () => { 

        setIsUploading(true); 

        const formData = new FormData() as any; 

        formData.append('file', file); 
        formData.append('title', title); 
        formData.append('folder', currentFolder._id); 
        formData.append('path', directory); 

        const res = await uploadFile(formData) as any;  

        setIsUploading(false); 

        if (res.data.status === 200) {

            dispatch(setIsFileUploaded(true));
            dispatch(setCurrentFolder(res.data.currentFolder)); 
            dispatch(setRefreshFileExplorer(true)); 
            dispatch(setNotificationMessage(t('fileUploaded')));
            dispatch(selectFile(null)); 
            dispatch(setMode(''));
            dispatch(resetUploadFile());

        } else if (res.data.status === 401) {

            if (res.data.error === 'invalidTitle') { 

                setTitleError(String(t('invalidTitle'))); 

            } else if (res.data.error === 'existingFile') { 

                setTitleError(String(t('existingFile'))); 

            }; 

        } else { 

            dispatch(setNotificationMessage(t('fileUploadFailed')));
                
        }; 

    };
    
    // -- 


    useEffect(() => { 

        dispatch(setTitle(text)); 

    },[text]); 

    // -- 

    useEffect(() => { 

        if (currentFolder.path === 'Recent' || currentFolder.path === 'Patients') { 

            setPath('Downloads');

        } else { 

            setPath(directory); 

        }; 

    },[currentFolder, directory]); 


    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 60, 
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '28%',
                    marginLeft: '2%', 
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: 'rgb(43,45,49)'
                }}
            >
                <FolderIcon sx={{ color: 'rgb(80,199,199)', fontSize: 15, marginLeft: 1 }} />

                <Box 
                    sx={{
                        fontSize: 12, 
                        marginLeft: 3
                    }}>
                    {path}
                </Box>

            </Box>


            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '30%',
                }}
            >
                <InsertDriveFileIcon sx={{ color: 'rgb(255,185,0)', fontSize: 15, marginLeft: 5 }} />

                <TextField
                    id="upload-file"
                    variant="standard"
                    value={title}
                    error={titleError ? true : false}
                    helperText={titleError}
                    InputProps={{
                        style: {fontSize: 12 },
                        disableUnderline: true, 
                    }}
                    onChange={(e) => setText(e.target.value)}
                    sx={{
                        marginLeft: 2,
                        fontSize: 12,
                        width: '80%'
                    }}
                />

            </Box>



            <Box style={{ 
                display: 'flex', 
                flexDirection: 'row-reverse',
                alignItems: 'center',  
                width: '40%',
            }}>

                <Button 
                    onClick={() => handleUnSelection()} 
                    sx={{ 
                        marginLeft: 1,
                        marginRight: 3,
                        backgroundColor: 'transparent',
                        borderColor: 'lightblue',
                        '&:hover': 
                        { 
                            backgroundColor: 'rgb(147, 147, 147)',
                            color: 'rgb(207, 207, 207)',
                            borderColor: 'rgb(147, 147, 147)'
                        }
                    }}
                    variant="contained">{t('cancel')}
                </Button>

            { (file && title) ? 
                <Button onClick={() => handleUpload()} sx={{ marginLeft: 5 }} variant="contained">{t('uploadFile')}</Button>: 
                <Button 
                disabled={true}
                sx={{ 
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                    marginLeft: 5
                }}
                variant="outlined">{t('uploadFile')}</Button>}

                    {isUploading && <CircularProgress />}
                    
                </Box>

        </Box>

    );

};

export default FileUpload;