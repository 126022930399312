import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '../../../themeContext';

type LogoProps = {
  size?: number;
};

const Logo: React.FC<LogoProps> = ({ size = 30 }) => {
  const { isLightMode } = useTheme();

  const logoSrc = isLightMode
    ? require('../../../assets/logos/medca_logo_blue_dark_blue.png')
    : require('../../../assets/logos/medca_logo_blue_white.png');

  return (
    <Box
      component="img"
      sx={{
        height: `${size}px`,
        width: 'auto',
      }}
      alt="Solutions Medca Inc."
      src={logoSrc}
    />
  );
};

export default Logo;