import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import Box from '@mui/material/Box';
import Header from './Header';
import CorrectionsList from './CorrectionsList';

const InfoSection: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const mode = useAppSelector(state => state.fileExplorer.mode); 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '30%',
            height: '95%', 
            borderRadius: 10,
            backgroundColor: '#313338',
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'width 0.3s',
        }}>

            <Header /> 

            <CorrectionsList /> 
            
        </Box>

    );

};

export default InfoSection;