
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setCurrentFolder, selectFolder, selectFile, setDocumentsFolder, setDownloadsFolder, setPatientsFolder, setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setIsOpened } from '../../../../../redux/features/doctor/Dashboard/documents/createFolder';

// Routes

import { getDoctorRootFolders } from '../../../../../routes/doctor/files';

// Utils 

import protectedPaths from './protectedPaths';

// Components

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FolderRow from './FolderRow';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const FoldersList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const refreshFileExplorer = useAppSelector((state) => state.fileExplorer.refreshFileExplorer); 
    const mode = useAppSelector((state) => state.fileExplorer.mode); 

    const [foldersList, setFoldersList] = useState([]) as any; 

    // -- 

    const changeSection = (selectedFolder: any) => { 

        dispatch(setCurrentFolder(selectedFolder)); 

        if (!protectedPaths.includes(selectedFolder.path)) { 

            !mode && dispatch(selectFolder(selectedFolder)); 
            !mode && dispatch(selectFile(null)); 

        }; 

        if (mode === 'sharedFolder') { 

            dispatch(setMode('')); 
            
        }; 

    }; 

    // --

    const getFoldersList = async () => { 

        const res = await getDoctorRootFolders() as any; 

        if (res?.data.status === '200') { 

            setFoldersList(res.data.dataList); 

            // For Redux purposes

            res.data.dataList?.forEach((folder: any) => { 

                if (folder.path === 'Patients') { 

                    dispatch(setPatientsFolder(folder)); 

                } else if (folder.path === 'Downloads') { 

                    dispatch(setDownloadsFolder(folder)); 

                } else if (folder.path === 'Documents') { 

                    dispatch(setDocumentsFolder(folder)); 

                }; 

            }); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        getFoldersList(); 

    },[]); 

    // -- 

    useEffect(() => { 

        if (refreshFileExplorer) { 

            getFoldersList(); 

        }; 

    },[refreshFileExplorer]); 

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%',
                height: '90%', 
                marginTop: 2,
            }}
        >

        { foldersList?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none' }}>
        
                <List dense disablePadding>
        
                {foldersList?.map((item, index) => (
                    item && (
                        <ListItem onClick={() => changeSection(item)} button key={index} disablePadding>
                            <FolderRow 
                                _id={item._id}
                                path={item.path}
                                title={item.title}
                                description={item.description}
                                status={item.status}
                                folder={item.folder} 
                                uploadedBy={item.uploadedBy}
                                relatedPatient={item.relatedPatient}
                                pinned={item.pinned}
                                createdAt={item.createdAt}
                                updatedAt={item.updatedAt}
                            />
                        </ListItem>
                    )
                ))}
        
                </List>
        
            </Box>}

          </Box>

    );

};

export default FoldersList;