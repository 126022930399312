import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import FolderDropdown from './FolderDropdown';
import FileDropdown from './FileDropdown';
import { Folder } from '@mui/icons-material';

const Dropdown: React.FC = () => {

    const mouseInfo = useAppSelector(state => state.fileExplorer.mouseInfo); 
    const isDropdownOpened = useAppSelector(state => state.fileExplorer.isDropdownOpened); 
    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 

    const [height, setHeight] = useState(-180); 

    // --

    useEffect(() => { 

        if (selectedFolder) { 

            selectedFolder?.protected && setHeight(-100); 

            if (selectedFolder?.relatedPatient) { 

                selectedFolder?.protected ? setHeight(-140) : setHeight(-260);

            };

        } else if (selectedFile) { 

            selectedFile?.relatedPatient && setHeight(-200); 

        };


    },[selectedFolder, selectedFile]); 

    // -- 

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 200,
                height: 'auto',
                bottom: height, 
                left: mouseInfo?.clientX - 490, 
                position: 'absolute',
                backgroundColor: 'rgb(82,84,89)',
                borderRadius: 4,
                zIndex: 5, 
                transition: 'width 0.3s',
            }}
        >

            { (isDropdownOpened && selectedFolder) && <FolderDropdown />}

            { (isDropdownOpened && selectedFile) && <FileDropdown />}

        </Box>

    )

}; 

export default Dropdown; 