import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setIsOpened, resetCreateFolder } from '../../../../../redux/features/doctor/Dashboard/documents/createFolder';

// Components 

import Action from '../actions/Action';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ShieldMoonIcon from '@mui/icons-material/ShieldMoon';
import DownloadIcon from '@mui/icons-material/Download';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RefreshIcon from '@mui/icons-material/Refresh';

const ListMetaHeader: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const accountInfo = useAppSelector((state) => state.meta.accountInfo); 

    const isOpened = useAppSelector((state) => state.createFolder.isOpened); 
    const directory = useAppSelector((state) => state.fileExplorer.directory);
    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile);
    const selectedFolder = useAppSelector((state) => state.fileExplorer.selectedFolder);
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 
    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 
    const patientsFolder = useAppSelector((state) => state.fileExplorer.patientsFolder); 
    const documentsFolder = useAppSelector((state) => state.fileExplorer.documentsFolder); 
    const mode = useAppSelector((state) => state.fileExplorer.mode); 

    const [isCurrentFolderProtected, setIsCurrentFolderProtected] = useState(false); 
    const [isPatientOptionDisabled, setIsPatientOptionDisabled] = useState(true); 

    // -- 

    useEffect(() => { 

        if (selectedFolder?.relatedPatient || selectedFile?.relatedPatient) { 

            setIsPatientOptionDisabled(false); 

        } else if (directory.startsWith('Patients/')) { 

            setIsPatientOptionDisabled(false); 

        } else { 

            setIsPatientOptionDisabled(true); 

        }; 

    },[selectedFolder, selectedFile, directory]); 

    // -- 

    useEffect(() => { 

        if (((directory === 'Patients') || (directory === 'Downloads'))) { 

            setIsCurrentFolderProtected(true); 

        } else if ((directory?.includes('Shared'))) { 

            setIsCurrentFolderProtected(true); 

        } else if (currentFolder?.uploadedBy) {
            
            if (currentFolder?.uploadedBy != accountInfo?._id) { 

                setIsCurrentFolderProtected(true); 

            } else { 

                setIsCurrentFolderProtected(false); 
            }; 

        } else { 

            setIsCurrentFolderProtected(false); 

        };

    },[directory]); 

    // --

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: '70%', 
            fontSize: 20,
        }}>

            <Action title={'newFolder'} iconColor='rgb(80,199,199)' action={'createFolder'} type='default' disabled={isCurrentFolderProtected}>
                <AddCircleOutlineIcon /> 
            </Action>

            <Action title={'uploadFile'} iconColor='rgb(34, 148, 6)' action={'uploadFile'} type='default' disabled={isCurrentFolderProtected}>
                <DownloadIcon /> 
            </Action>

            <Action title={'patientInfo'} iconColor={'rgb(30, 144, 255)'} action={'patientInfo'} type='default' disabled={isPatientOptionDisabled}>
                <AccountCircleIcon /> 
            </Action>

            <Action title={'refresh'} iconColor='rgb(80,199,199)' action={'refreshFileExplorer'} type='default'>
                <RefreshIcon /> 
            </Action>

        </Box>

    );

};

export default ListMetaHeader;