import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

// Routes

import { createPatient, updatePatient } from '../../../../../../routes/doctor/patients';

// Redux 

import { setErroredCategory, setErroredField, setResetCreatePatient } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setNotificationMessage } from '../../../../../../redux/features/general/notification';
import { setPatientIdFromFileExplorer, setSection } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setSection as setCreatePatientSection } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Utils 

import requiredFields from '../../../../../../util/dashboard/doctor/patient/requiredFields';
import { DisabledByDefault } from '@mui/icons-material';

const SubmitButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const section = useAppSelector(state => state.createPatient.section) as string; 
    const patient = useAppSelector((state) => state.createPatient) as any; 
    const completedFields = useAppSelector((state) => state.createPatient.completedFields) as any; 
    const editedPatient = useAppSelector((state) => state.createPatient.editedPatient) as any; 

  // -- 

  const handlePatientCreation = async () => { 

    // We check if all required fields have been checked. 

    const listOfUncompletedRequiredFields = requiredFields.filter((field) => { 

      if (!completedFields.includes(field)) { 

        dispatch(setErroredField({ field: field, error: 'requiredField' }));

        return field; 

      }; 

    }); 

    if (listOfUncompletedRequiredFields.length > 0) { 

      return; 

    }; 

    let res = {} as any; 

    if (editedPatient) { 

      res = await updatePatient(patient) as any; 

    } else { 

      res = await createPatient(patient) as any; 

    }; 

    if (res.data.status === 200) { 

      editedPatient && dispatch(setNotificationMessage(t('patientUpdated')));
      !editedPatient && dispatch(setNotificationMessage(t('createPatientOK')));

      dispatch(setResetCreatePatient());
      dispatch(setPatientIdFromFileExplorer(res.data.patient._id));
      dispatch(setSection('patient')); 

      // A refresh feature should also be added. 

    } else if (res.data.status === 401) { 

      if (res.data.erroredCategories) { 

        dispatch(setCreatePatientSection(res.data.erroredCategories[0])); 

        res.data.erroredCategories.forEach((category: any) => { 

          dispatch(setErroredCategory(category)); 

        }); 

      };

      if (res.data.erroredFields) { 

        res.data.erroredFields.forEach((field: any) => { 

          dispatch(setErroredField(field)); 

        }); 

      }; 

    }; 

  }; 


  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 1,
        height: '5vh',
        width: '100%',
    }}>

       
      <Button 
        onClick={() => handlePatientCreation()}
        sx={{ width: '75%', backgroundColor: 'rgba(59, 87, 196, 0.5)' }} 
        variant="contained">{editedPatient ? t('updateDetails') : t('createPatient')}
      </Button>
              
    </Box>

  );

};

export default SubmitButton;