import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Routes 

import { getDoctors } from '../../../routes/hacker/users';

// Components 

import ListHeader from '../../hacker/doctors/ListHeader';
import DoctorRow from './DoctorRow';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { setIsRefreshDoctorsList, setSelectedDoctor } from '../../../redux/features/admin/dashboard/users/userAccess';
import Dropdown from './Dropdown';

const DoctorsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const refreshUsersList = useAppSelector(state => state.adminUserAccess.refreshUsersList); 
    const isRefreshDoctorsList = useAppSelector(state => state.adminUserAccess.isRefreshDoctorsList);  
    const selectedDoctor = useAppSelector(state => state.adminUserAccess.selectedDoctor);  

    const [doctors, setDoctors] = useState(Array<any>);

    const [postionX, setPositionX] = useState(0); 
    const [positionY, setPositionY] = useState(0); 

    // -- 

    const getDoctorsList = async () => { 

      const res = await getDoctors() as any; 

      if (res.data.status === '200') { 

        setDoctors(res.data.doctors); 

      };

    }; 

    // -- 

    const handleSelection = (e: any, doctor: any) => { 

      if (selectedDoctor) { 

        if (selectedDoctor?._id == doctor?._id) { 

          dispatch(setSelectedDoctor(null)); 

          return; 
  
        }; 

      }; 

      setPositionX(e.pageX);
      setPositionY(e.pageY); 

      dispatch(setSelectedDoctor(doctor)); 

    }; 

    // -- 

    useEffect(() => { 

        getDoctorsList(); 

    },[]); 

    // -- 

    useEffect(() => { 

      if (isRefreshDoctorsList) { 

        getDoctorsList(); 

        dispatch(setIsRefreshDoctorsList(false)); 

      }; 


    },[isRefreshDoctorsList]); 

    // -- 
    
    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '95vw',
              backgroundColor: '#383A40',
            }}>            
            
            <ListHeader />

            { doctors?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2vh' }}>
        
                <List dense>
        
                {doctors?.map((item, index) => (
        
                    <ListItem onClick={(e) => handleSelection(e, item)} button key={index}>

                        <DoctorRow 
                          firstName={item.firstName}
                          lastName={item.lastName}
                          type={item.accountStatus}
                          email={item.email}
                          createdAt={moment.utc(item.createdAt).format('LL')}
                          accountStatus={item.accountStatus} 
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {doctors?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              color: '#C2C5C8'
            }}>

              <PersonAddAlt1Icon sx={{ fontSize: 70 }} />

              <div>No doctor account detected.</div>

          </Box>}

          { selectedDoctor && <Dropdown positionX={postionX} positionY={positionY} />}
        

            </Box>

        </Box>
    
      );

};

export default DoctorsList;