import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components 

import HeaderSection from '../header/HeaderSection';
import FilesList from './FilesList';
import Footer from '../footer/Footer';
import FileViewer from '../actions/fileViewer/FileViewer';


const FilesSection: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const mode = useAppSelector(state => state.fileExplorer.mode); 

    return (

        <Box 
            
        style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '80%',
            height: '95%', 
            borderRadius: 10,
            backgroundColor: '#313338',
            
        }}>

            <HeaderSection />

            <FilesList />

            <Footer /> 

            { mode === 'viewFile' && <FileViewer />}
            
        </Box>

    );

};

export default FilesSection;