

const menuItems = [

    { type: 'item', section: 'documents', text: 'docs', iconName: 'home', iconColor: 'white'},

    { type: 'header', text: 'Management'},

    { type: 'item', section: 'teams', text: 'teams', iconName: 'group', iconColor: 'white'},

    { type: 'item', section: 'createTeam', text: 'createTeam', iconName: 'add_circle_outline', iconColor: 'white'},

    { type: 'header', text: 'Access'},

    { type: 'item', section: 'users', text: 'users', iconName: 'people', iconColor: 'white'},

    { type: 'item', section: 'createUser', text: 'add', iconName: 'person_add', iconColor: 'white'},


]; 

export default menuItems; 