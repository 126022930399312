import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';


// Redux 

import { setCompletedCategory, setIncompletedCategory, removeCompletedCategory, removeIncompletedCategory, removeErroredField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setEmergencyContactName, setEmergencyContactRelation, setEmergencyContactPhone, setSection, setCompletedField } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputHeader from './items/InputHeader';
import BottomSpace from './items/BottomSpace';
import InputRow from './items/InputRow';

// Icons 

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

// Styles 

import iconStyle from './styles/iconStyle';
import inputPropsStyle from './styles/inputPropsStyle';
import textFieldStyle from './styles/textFieldStyle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const EmergencyContact: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const section = useAppSelector((state) => state.createPatient.section);
    const editedPatient = useAppSelector(state => state.createPatient.editedPatient);  
    const completedCategories = useAppSelector((state) => state.createPatient.completedCategories);
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const erroredFields = useAppSelector((state) => state.createPatient.erroredFields);
    const nextSection = useAppSelector((state) => state.createPatient.nextSection);
    
    const emergencyContactName = useAppSelector((state) => state.createPatient.emergencyContactName); 
    const emergencyContactRelation = useAppSelector((state) => state.createPatient.emergencyContactRelation); 
    const emergencyContactPhone = useAppSelector((state) => state.createPatient.emergencyContactPhone); 

    const [emergencyContactNameError, setEmergencyContactNameError] = useState(''); 
    const [emergencyContactRelationError, setEmergencyContactRelationError] = useState(''); 
    const [emergencyContactPhoneError, setEmergencyContactPhoneError] = useState(''); 

    const emergencyContactNameRef = useRef() as any; 
    const emergencyContactRelationRef = useRef() as any;  
    const emergencyContactPhoneRef = useRef() as any; 

    // -- 

    const handleInput = (input: any, section: any) => { 

        if (section === 'emergencyContactName') { 

            dispatch(setEmergencyContactName(input)); 

        } else if (section === 'emergencyContactRelation') { 

            dispatch(setEmergencyContactRelation(input)); 

        } else if (section === 'emergencyContactPhone') { 

            dispatch(setEmergencyContactPhone(input)); 

        };

    };

    // -- 


    const confirmInput = (key: string, field: string) => { 

        if (key == 'Enter') { 

            if (field === 'emergencyContactName') { 

                emergencyContactRelationRef.current.focus(); 
    
            } else if (field === 'emergencyContactRelation') { 

                emergencyContactPhoneRef.current.focus(); 
    
            } else if (field === 'emergencyContactPhone') { 
    
                if (section !== 'confirmation') { 

                    dispatch(setSection(nextSection)); 

                };

            };

        }; 

    };

    // -- 

    useEffect(() => { 

        if (erroredFields.length > 0) { 

            setEmergencyContactNameError('');
            setEmergencyContactRelationError('');
            setEmergencyContactPhoneError('');

            erroredFields.forEach((item: any) => { 

                if (item.field === 'emergencyContactName') { 

                    setEmergencyContactNameError(item.error); 

                } else if (item.field === 'emergencyContactRelation') { 

                    setEmergencyContactRelationError(item.error); 

                } else if (item.field === 'emergencyContactPhone') { 

                    setEmergencyContactPhoneError(item.error); 

                }; 

            });

        }; 

    },[erroredFields]); 



    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: section === 'confirmation' ? 'auto' : '95%',
        }}>

            { !editedPatient && <InputHeader title={'emergencyContactName'} />}

            { !editedPatient && 
            <InputRow 
                icon={<AccountBoxIcon sx={iconStyle} /> } 
                iconColor='rgb(45, 207, 196)' 
                value={emergencyContactName}
                error={emergencyContactNameError}>

                <TextField
                    value={emergencyContactName}
                    placeholder={String(t('emergencyContactName'))}
                    onChange={(e) => handleInput(e.target.value, 'emergencyContactName')}
                    autoFocus
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'emergencyContactName')}
                    inputRef={emergencyContactNameRef}
                />
            </InputRow>}

            { !editedPatient && <InputHeader title={'emergencyContactRelation'} />} 

            { !editedPatient && 
            <InputRow 
                icon={<FamilyRestroomIcon sx={iconStyle} /> } 
                iconColor='rgb(188, 45, 207)' 
                value={emergencyContactRelation}
                error={emergencyContactRelationError}>

                <TextField
                    value={emergencyContactRelation}
                    placeholder={String(t('emergencyContactRelation'))}
                    onChange={(e) => handleInput(e.target.value, 'emergencyContactRelation')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'emergencyContactRelation')}
                    inputRef={emergencyContactRelationRef}
                />
            </InputRow>}

            { !editedPatient && <InputHeader title={'emergencyContactPhone'} />}

            { !editedPatient && 
            <InputRow 
                icon={<LocalPhoneIcon sx={iconStyle} /> } 
                iconColor='rgb(45, 153, 207)' 
                value={emergencyContactPhone}
                error={emergencyContactPhoneError}>

                <TextField
                    value={emergencyContactPhone}
                    placeholder={String(t('emergencyContactPhone'))}
                    onChange={(e) => handleInput(e.target.value, 'emergencyContactPhone')}
                    variant="standard"
                    autoComplete='off'
                    InputProps={inputPropsStyle}
                    sx={textFieldStyle}
                    onKeyDown={(e) => confirmInput(e.key, 'emergencyContactPhone')}
                    inputRef={emergencyContactPhoneRef}
                />
            </InputRow>}


            { editedPatient && 

            <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'width',
                    alignItems: 'center', 
                    width: '100%',
                    backgroundColor: 'rgb(52, 53, 58)',
                    paddingLeft: 2,
                    borderRadius: 4, 
                    height: 50,
                    fontSize: 12, 
                    color: '#C2C5C8', 
                }}>{t('patientsEmergencyContactsNotAvailable')}
                </Box>}

            <BottomSpace /> 

        </Box>
    );
};

export default EmergencyContact;