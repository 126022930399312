import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { resetScanTemplates, setResetInputValue, setScanTemplateSection } from '../../../redux/features/doctor/Dashboard/templates/scanTemplate';
import { uploadTemplateImages } from '../../../routes/doctor/templates';

const Upload: React.FC = () => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { tempId } = useParams<{ tempId: string }>();

    const images = useAppSelector(state => state.scanTemplate.images); 

    const [templateTitle, setTemplateTitle] = useState(`My Template ${Math.floor(1000 + Math.random() * 9000)}`); 

    const handleFileUpload = async () => {


        if ((images.length > 0) && templateTitle) { 

            const formData = new FormData();

            formData.append('title', templateTitle);

            images.forEach((img, index) =>
              formData.append('images', dataURLtoBlob(img), `scanned_page_${index + 1}.png`)
            );

            const res = await uploadTemplateImages(tempId, formData) as any; 

            if (res.data.status === 200) { 

                dispatch(setScanTemplateSection('success')); 

            }; 

        };
    

    };

    // -- 

    const dataURLtoBlob = (dataURL: string) => {

        const arr = dataURL?.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      };

    // -- 

    const handleQuit = () => {

        dispatch(setResetInputValue(true)); 
        dispatch(resetScanTemplates()); 

    }; 

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh', 
            width: '100vw',
            right: 0, 
            overflowX: 'hidden',
            overflowY: 'hidden', 
            position: 'absolute',
            color: 'white' 
        }}>

            <Box    
                sx={{ 
                    overflow: 'auto',
                    width: '100%',
                    height: '65%',
                    marginTop: '5%',
                    scrollbarWidth: 'none' 
                }}>

                {images.map((img, index) => (

                <Box 
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        width: '100%',
                        height: 'auto', 
                        marginTop: 3, 
                        marginBottom: 3
                    }} 
                    key={index}>
                    <Box component="img" src={img} alt={`Scanned Page ${index + 1}`} sx={{ width: '66%' }} />
                </Box>
                ))}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    height: '30%', 
                }}
            >

                <TextField
                    id="standard-search"
                    variant="standard"
                    autoComplete='off'
                    autoFocus
                    value={templateTitle}
                    InputProps={{
                        style: {
                            fontSize: 16, 
                            textAlign: 'center' },
                        disableUnderline: true, 
                    }}
                    onChange={(e: any) => setTemplateTitle(e.target.value)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        width: 300,
                        minHeight: 50, 
                        paddingLeft: 1,
                        marginTop: 2,
                        borderRadius: 2,
                        backgroundColor: 'rgb(13, 14, 15)',
                        borderBottomColor: 'grey'
                    }}
                />

                <Button 
                    onClick={handleFileUpload}
                    sx={{ width: 300, minHeight: 50, paddingLeft: 5, paddingRight: 5, marginTop: 2, backgroundColor: 'rgba(59, 87, 196, 0.5)' }} 
                    variant="contained">{t('createTemplate')}
                </Button>

                <Box
                    onClick={handleQuit}
                    sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    padding: 2,
                    cursor: 'pointer'
                }}
                >Back</Box>

            </Box>

        </Box>

  );
};

export default Upload;