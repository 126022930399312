import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components
import NavBar from '../../general/navBar/NavBar';
import Settings from '../navigation/navBar/Settings';
import Sidebar from '../../general/navigation/Sidebar';

// Redux
import { setSideBarMenu } from '../../../redux/features/general/navigation';

// Navigation menus
import menuItems from '../../../util/navigation/sidebarMenu/doctor';
import doctorDashboardMenu from '../../../util/navigation/dashboard/doctor';

// Dashboard Interfaces 

import FileExplorer from './fileExplorer/FileExplorer';
import Templates from './templates/Templates';

import PatientsInterface from './PatientsInterface';
import InsurersInterface from './InsurersInterface';

import { useTranslation } from 'react-i18next';



const Container: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const section = useAppSelector((state) => state.navigation.section);

  const container = document.getElementById("mainContainer") as any; 

  container?.addEventListener("contextmenu", (e: any) => {e.preventDefault()});

  useEffect(() => {
    
    dispatch(setSideBarMenu(menuItems));

  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        id='mainContainer'
        component="main"
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          left: 0,
          zIndex: 0,
          overflowX: 'hidden',
          position: 'absolute',
          color: 'white',
        }}
      >
        <NavBar>
          {/* Items added to the navbar */}
          <Settings />
        </NavBar>

        <Sidebar />

        {/* SideBar sections */}
        {section === 'documents' && <FileExplorer />}
        {section === 'patients' && <PatientsInterface />}
        {section === 'insurers' && <InsurersInterface />}
        {section === 'templates' && <Templates />}
      </Box>
      
    </React.Fragment>
  );
};

export default Container;