import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
// Routes

import { deleteDoctor } from '../../../routes/hacker/users';

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NotesIcon from '@mui/icons-material/Notes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';
import { setIsRefreshDoctorsList, setResetUserAccess, setSelectedDoctor } from '../../../redux/features/admin/dashboard/users/userAccess';
import { setNotificationMessage } from '../../../redux/features/general/notification';



type Props = {
  positionX: number,
  positionY: number,
};


const Dropdown: React.FC<Props> = ({positionX, positionY }) => {

    const { t } = useTranslation(); 

    const dispatch = useAppDispatch(); 

    const selectedDoctor = useAppSelector((state) => state.adminUserAccess.selectedDoctor); 

    // -- 

    const handleDeleteDoctor = async () => { 

        const res = await deleteDoctor(selectedDoctor._id); 

        if (res?.data.status === 200) { 

            dispatch(setIsRefreshDoctorsList(true)); 
            dispatch(setSelectedDoctor(null));
            dispatch(setNotificationMessage('Doctor account is now inactive.')); 

        }; 

    };   
    
    // -- 
    
    return (


    <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 200,
            height: 50,
            left: positionX - 170, 
            top: positionY, 
            position: 'absolute',
            backgroundColor: 'rgb(82,84,89)',
            borderRadius: 4,
            cursor: 'pointer',
            zIndex: 5, 
        }}
    >

            <Box
                    onClick={handleDeleteDoctor}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 50,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: 'red'}
                    }}
                >

                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                    >
                        <DeleteOutlineIcon /> 
                        <Box sx={{ marginLeft: 3 }}>{t('makeInactive')}</Box>
                </Box>

            </Box>

        </Box>

    ); 

};

export default Dropdown;













