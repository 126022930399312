import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';

type Props = {
    children?: React.ReactNode
};

const Settings: React.FC<Props> = ({children}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openSupport, setOpenSupport] = React.useState(false);

    const handleOpenSupport = () => setOpenSupport(true);
    const handleCloseSupport = () => setOpenSupport(false);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.clear();
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        navigate('/login');
        handleClose();
    };

    const handleRedirect = (path: string) => {
        navigate(path);
        handleClose();
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ backgroundColor: 'transparent' }}
            >
                <AccountCircleIcon sx={{ cursor: 'pointer', color: '#FFFFFF' }} />
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
                <MenuItem onClick={handleOpenSupport}>{t('support')}</MenuItem>
                <MenuItem onClick={() => handleRedirect('/faq')}>{t('faq')}</MenuItem>
                <MenuItem onClick={() => handleRedirect('/tos')}>{t('termsOfService')}</MenuItem>
                <MenuItem onClick={() => handleRedirect('/privacy')}>{t('privacy')}</MenuItem>
            </Menu>

            <Modal
                open={openSupport}
                onClose={handleCloseSupport}
                aria-labelledby="support-modal-title"
                aria-describedby="support-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid gray',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                </Box>
            </Modal>
        </div>
    );
};

export default Settings;