import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { resetFileViewer } from '../../../../../../redux/features/doctor/Dashboard/documents/fileViewer';
import { setMode } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components 

import Box from '@mui/material/Box';
import Action from '../Action';
import OptionsRow from './OptionsRow';
import Button from '@mui/material/Button';

// Icons 

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


const FileViewerOptions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 

    const handleQuit = () => { 

        dispatch(setMode(''));
        dispatch(resetFileViewer()); 

    }; 
    
    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '100%',
            marginTop: 1,
        }}>

            <Box
                onClick={handleQuit} 
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: 40, 
                    marginBottom: 5,
                    cursor: 'pointer', 
                    '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.5)'}
                }}
            >
                <ArrowCircleLeftOutlinedIcon sx={{ marginLeft: 2 }} /> 
                <Box sx={{ marginLeft: 1, fontSize: 12 }}>{t('quitView')}</Box>

            </Box>

            { selectedFile && 
            
            <>

            <OptionsRow>
                <Action title={'correctFile'} iconColor={'rgb(80,199,199)'} action={'correctFile'} type='default'>
                    <AutoAwesomeIcon /> 
                </Action>
            </OptionsRow>
            
            <OptionsRow>
                <Action title={'renameFile'} iconColor={'rgb(34, 148, 6)'} action={'renameFile'} type='default'>
                    <DriveFileRenameOutlineIcon /> 
                </Action>
            </OptionsRow>

            <OptionsRow>
                <Action title={'moveTo'} iconColor={'rgb(30, 144, 255)'} action={'moveFile'} type='default'>
                    <DriveFileMoveIcon /> 
                </Action>
            </OptionsRow>

            { selectedFile?.relatedPatient &&

            <OptionsRow>
            <Action title={'patientInfo'} iconColor={'rgb(127, 52, 235)'} action={'patientInfo'} type='default'>
                <AccountCircleIcon /> 
            </Action>
            </OptionsRow>}

            <OptionsRow>
                <Action title={'deleteFile'} iconColor={'rgb(201, 79, 42)'} action={'deleteFile'} type='default'>
                    <DeleteIcon /> 
                </Action>
            </OptionsRow>

            </>}

        </Box>

    );

};

export default FileViewerOptions;