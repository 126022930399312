import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Legend: React.FC = () => {

    const { t } = useTranslation();

    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile) as any; 
    const selectedFolder = useAppSelector((state) => state.fileExplorer.selectedFolder) as any; 

    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '3vh',
            width: '100%',
            fontSize: 12, 
            backgroundColor: '#2B2D31',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',        
            color: '#C2C5C8'
        }}>

            <>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%',
                    marginLeft: '2%',  
                }}>
                    { (selectedFile || selectedFolder) ? 
                    <CheckCircleIcon sx={{ fontSize: 15, color: 'rgb(26, 255, 0)', marginLeft: 1 }} /> 
                    : <CircleOutlinedIcon sx={{ fontSize: 15, marginLeft: 1 }} />}
                </div>
                
                <Box sx={{ width: '30%' }}>{t('name')}</Box>
                <Box sx={{ width: '30%', marginLeft: '1%' }}>{t('type')}</Box>
                <Box sx={{ width: '15%' }}>{t('dateCreated')}</Box>
                <Box sx={{ width: '15%' }}>{t('dateModified')}</Box>
            </>
        </Box>
    );
};

export default Legend;