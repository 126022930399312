import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface TemplatesState {
    section: string, 
    templatesList: any[], 
    qrValue: any, 
    tempLinkId: string, 
    refreshTemplatesList: boolean, 
  };
  
  // ---------------
  
  const initialState: TemplatesState = {
    section: 'list', 
    templatesList: [], 
    qrValue: null, 
    tempLinkId: '', 
    refreshTemplatesList: false
  };
  
  // ---------------
  
  export const templatesSlice = createSlice({
  
    name: 'templates',
  
    initialState,
  
    reducers: {
      
      setTemplatesSection: (state, action: PayloadAction<any>) => {
  
        state.section = action.payload; 
  
      },
      setTemplatesList: (state, action: PayloadAction<any>) => {
  
        state.templatesList = action.payload; 
  
      },
      setQrValue: (state, action: PayloadAction<any>) => {
  
        state.qrValue = action.payload; 
  
      },
      setTempLinkId: (state, action: PayloadAction<string>) => {
  
        state.tempLinkId = action.payload; 
  
      }, 
      setRefreshTemplatesList: (state, action: PayloadAction<boolean>) => {
  
        state.refreshTemplatesList = action.payload; 
  
      }, 
      resetTemplates: (state) => { 
          
          state.section = 'list'; 
          state.templatesList = []; 
          state.qrValue = null; 
          state.tempLinkId = ''; 
          state.refreshTemplatesList = false; 
  
      } 
    },
  
  }); 
  
  // ---------------
  
  export const { 
      setTemplatesSection,
      setTemplatesList, 
      setQrValue, 
      setTempLinkId, 
      setRefreshTemplatesList, 
      resetTemplates
   } = templatesSlice.actions;
  
  export default templatesSlice.reducer;