import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface hackerDataState {
  section: string,
  data: any, 
  securityData: any, 
  doctorsChartData: any[],
  patientsChartData: any[], 
  doctorsChartXAxis: any[], 
  patientsChartXAxis: any[],
}

// ---------------

const initialState: hackerDataState = {
    section: 'doctors', 
    data: null, 
    securityData: null, 
    doctorsChartData: [],
    patientsChartData: [], 
    doctorsChartXAxis: [], 
    patientsChartXAxis: [],
};

// ---------------


export const hackerDataSlice = createSlice({

  name: 'hackerData',

  initialState,

  reducers: {

    setHackerDataSection: (state, action: PayloadAction<string>) => {

        state.section = action.payload; 
    },
    setHackerData: (state, action: PayloadAction<any>) => {

      state.data = action.payload; 
    },
    setSecurityData: (state, action: PayloadAction<any>) => {

      state.securityData = action.payload; 
    },
    setDoctorsChartData: (state, action: PayloadAction<any>) => {

      if (state.doctorsChartData.length < 14) { 

        state.doctorsChartData.push(action.payload); 

      }; 

    }, 
    setPatientsChartData: (state, action: PayloadAction<any>) => {

      if (state.patientsChartData.length < 14) { 

        state.patientsChartData.push(action.payload); 

      }; 

    }, 
    setDoctorsChartXAxis: (state, action: PayloadAction<any>) => {

      if (state.doctorsChartXAxis.length < 14) { 

        state.doctorsChartXAxis.push(action.payload); 

      }; 

    }, 
    setPatientsChartXAxis: (state, action: PayloadAction<any>) => {

      if (state.patientsChartXAxis.length < 14) { 

        state.patientsChartXAxis.push(action.payload); 

      }; 

    }, 
    resetHackerData: (state) => { 
        
        state.section = 'doctors';
        state.data = null; 
        state.securityData = null; 
        state.doctorsChartData = [];
        state.patientsChartData = [];
        state.doctorsChartXAxis = []; 
        state.patientsChartXAxis = []; 

    } 

  },

}); 

// ---------------

export const { 
    setHackerDataSection,
    setHackerData,  
    setSecurityData, 
    setDoctorsChartData, 
    setPatientsChartData,
    setDoctorsChartXAxis, 
    setPatientsChartXAxis,  
    resetHackerData } = hackerDataSlice.actions;

export default hackerDataSlice.reducer;