import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';


// Components 

import NavigationBar from './navigation/NavigationBar';
import CategoryTitle from './categories/items/CategoryTitle';
import ProgressColumn from './navigation/ProgressColumn';
import EditUpdater from './actions/EditUpdater';

import PatientInfo from './categories/PatientInfo';
import ContactInfo from './categories/ContactInfo';
import EmergencyContact from './categories/EmergencyContact';
import InsuranceDetails from './categories/InsuranceDetails';
import ExtraInformation from './categories/ExtraInformation';
import Confirmation from './categories/Confirmation';

import Popup from '../../../../general/Popup';
import ExitProcess from './actions/ExitProcess';
import { setSection } from '../../../../../redux/features/doctor/Dashboard/patients/createPatient';

const CreatePatient: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // -- 

  const scrollListRef = useRef() as any; 

  const isPortraitMode = useMediaQuery('(orientation: portrait)');

  const section = useAppSelector((state) => state.createPatient.section); 
  const interfaceSection = useAppSelector((state) => state.patient.section); 
  const completedFields = useAppSelector((state) => state.createPatient.completedFields); 
  const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 
  const editedPatient = useAppSelector(state => state.createPatient.editedPatient);  

  // -- 

  useEffect(() => { 

    if (section) { 

      scrollListRef.current.scrollTo(0, 0);

    }; 

  },[section]); 

  // -- 

  window.onbeforeunload = function (e: any) {

    if ((completedFields?.length > 0) && (interfaceSection === 'createPatient')) { 

      // For IE and Firefox

      if (e) {
        e.returnValue;
      }; 
    
      // For Safari
      return e.returnValue;

    };

  };

  // -- 

  return (

    <Box
      sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%', 
        bottom: 0,
        position: 'absolute',
        backgroundColor: '#201F1E',
        transition: 'left 0.3s',
    }}>

      <ProgressColumn isPortraitMode={isPortraitMode} />

      <Box
        sx={{
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          width: '70%',
          height: '95%', 
          bottom: 0,
          borderRadius: 5,
          backgroundColor: '#383A40',
          transition: 'left 0.3s',    
        }}
      >

          <Box
            ref={scrollListRef}
            sx={{
              width: '100%',
              padding: '2rem',
              "& .MuiTextField-root": { marginBottom: '1rem' },
              "& .MuiFormControl-root": { marginBottom: '1rem' },
              maxHeight: '80vh',
              overflow: 'auto',
              scrollbarWidth: 'none',
            }}
          >

            <CategoryTitle /> 

            { section === 'patientInfo' && <PatientInfo />}

            { section === 'contactInfo' && <ContactInfo />}

            { section === 'emergencyContact' && <EmergencyContact />}

            { section === 'insuranceDetails' && <InsuranceDetails />}

            { section === 'extraInformation' && <ExtraInformation />}

            { section === 'confirmation' && <Confirmation />}

            <NavigationBar />

          </Box>

      </Box>

      { isPopupOpened && 
          <Popup>
            <ExitProcess />
          </Popup>}

      <EditUpdater />

    </Box>
  );
};

export default CreatePatient;