
// -- Gets the appropriate background color for the hacker's 
// support tickets list. 

const getStatusColor = (status: any) => {

    if (status === 'active') { 

        return 'rgba(73, 176, 55, 0.5)'; 

    } else if (status === 'archived') { 

        return 'rgba(150, 75, 0, 0.5)'; 
        
    } else { 

        return 'transparent'; 
    }

};

export default getStatusColor; 