import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { resetSearchPatient, setAddress, setDateOfBirth, setEmail, setFirstName, setHealthCardNumber, setIsFiltersDropdownOpened, setLastName, setPatientId, setPatientsList, setPhone } from '../../../../../../../../redux/features/doctor/Dashboard/patients/searchPatient';

// Routes

import { searchPatient } from '../../../../../../../../routes/doctor/patients';

// Components 

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Filters from './Filters';
import PatientsList from './PatientsList';
import Button from '@mui/material/Button';
import FiltersDropdown from './FiltersDropdown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PatientFound from './PatientFound';

const SearchBar: React.FC = () => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation(); 

    const filters = useAppSelector((state) => state.searchPatient.filters); 
    const isFiltersDropdownOpened = useAppSelector((state) => state.searchPatient.isFiltersDropdownOpened); 
    const firstName = useAppSelector((state) => state.searchPatient.firstName); 
    const lastName = useAppSelector((state) => state.searchPatient.lastName); 
    const dateOfBirth = useAppSelector((state) => state.searchPatient.dateOfBirth); 
    const email = useAppSelector((state) => state.searchPatient.email); 
    const phone = useAppSelector((state) => state.searchPatient.phone); 
    const patientId = useAppSelector((state) => state.searchPatient.patientId); 
    const healthCardNumber = useAppSelector((state) => state.searchPatient.healthCardNumber); 
    const address = useAppSelector((state) => state.searchPatient.address); 
    const patientsList = useAppSelector((state) => state.searchPatient.patientsList); 

    const patientFound = useAppSelector((state) => state.searchPatient.patientFound); 

    const [placeholder, setPlaceholder] = useState(''); 

    const [text, setText] = useState(''); 

    const [dropdownPositionX, setDropdownPositionX] = useState(0); 
    const [dropdownPositionY, setDropdownPositionY] = useState(0); 

    const handleSearch = async () => { 

        const content = { 
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dateOfBirth,
            email: email,
            phone: phone,
            patientId: patientId,
            healthCardNumber: healthCardNumber,
            address: address, 
        }; 

        const res = await searchPatient(content) as any; 

        if (res.data.status === 200) { 

            dispatch(setPatientsList(res.data.patientsList)); 

        }; 

    }; 

    // -- 

    const hanleEnterOption = (e: any) => { 

        if (e.key === 'Enter') { 

            handleSearch(); 

        }; 

    }; 

    // -- 

    const handleQuit = () => { 

        dispatch(resetSearchPatient()); 
        setText('');

    }; 

    // -- 

    const handleFiltersDropdown = (e: any) => { 

        if (isFiltersDropdownOpened) { 

            dispatch(setIsFiltersDropdownOpened(false)); 

        } else { 

            setDropdownPositionX(e.pageX); 
            setDropdownPositionY(e.pageY); 

            dispatch(setIsFiltersDropdownOpened(true)); 

        };

    }; 

    // -- 

    useEffect(() => { 

        if (filters.length > 0) { 

            setPlaceholder(`${String(t('searchPatient'))} ${t('by')} ${t(filters[filters.length - 1])}`);

        } else { 

            setPlaceholder(`${String(t('searchPatient'))} ${t('by')} ${t('lastName')}`);

        }; 

    },[filters]); 

    // --

    useEffect(() => { 

        if (filters.length > 0) { 

            filters[filters.length - 1] === 'firstName' && dispatch(setFirstName(text)); 
            filters[filters.length - 1] === 'lastName' && dispatch(setLastName(text)); 
            filters[filters.length - 1] === 'dateOfBirth' && dispatch(setDateOfBirth(text));
            filters[filters.length - 1] === 'email' && dispatch(setEmail(text));
            filters[filters.length - 1] === 'phone' && dispatch(setPhone(text));
            filters[filters.length - 1] === 'patientId' && dispatch(setPatientId(text));
            filters[filters.length - 1] === 'address' && dispatch(setAddress(text));
            filters[filters.length - 1] === 'healthCardNumber' && dispatch(setHealthCardNumber(text));
            
        } else { 

            dispatch(setLastName(text)); 

        }; 

    },[text]); 

    // -- 

    useEffect(() => { 

        if (firstName || lastName || dateOfBirth || email || phone || patientId || address || healthCardNumber) { 

            handleSearch(); 

        };

    },[firstName, lastName, dateOfBirth, email, phone, patientId, address, healthCardNumber]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: 100,
            marginBottom: '1%',
            fontSize: 12, 
        }}>

            <Filters /> 

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                height: 50, 
                borderRadius: 4,
                // backgroundColor: '#383A40',
            }}>

                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '80%',
                    height: '100%', 
                    borderRadius: 4,
                    marginLeft: 5,
                    backgroundColor: 'rgb(43,45,49)',
                    // backgroundColor: '#383A40',
                }}>
                    <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: 'grey' }}/>
                    <TextField
                        onKeyDown={hanleEnterOption}
                        id="standard-search"
                        placeholder={placeholder}
                        variant="standard"
                        autoComplete='off'
                        value={text}
                        InputProps={{
                            style: {fontSize: 12 },
                            disableUnderline: true, 
                        }}
                        onChange={(e) => setText(e.target.value)}
                        sx={{
                            marginLeft: 2,
                            fontSize: 12,
                            width: '100%',
                            borderBottomColor: 'grey'
                        }}
                    />

                    <Box
                        onClick={handleQuit}
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            alignItems: 'center', 
                            justifyContent: 'space-evenly',
                            width: 30,
                            height: 30, 
                            cursor: 'pointer',
                            color: '#C2C5C8',
                            borderRadius: '50%',
                            marginRight: 2, 
                            '&:hover': { backgroundColor: 'rgba(127, 135, 135, 0.2)'},
                        }}
                    >
                        <HighlightOffIcon sx={{ '&:hover': { color: 'white' }}}/> 
                    </Box>

                </Box>

                <Button 
                    onClick={handleFiltersDropdown}
                    sx={{ 
                        width: 100, 
                        paddingLeft: 1, 
                        paddingRight: 1, 
                        textTransform: 'none', 
                        marginLeft: 3, 
                        backgroundColor: 'rgba(59, 87, 196, 0.5)' }} 
                    variant="contained">{t('addFilter')}
                </Button>

            </Box>

            { isFiltersDropdownOpened && <FiltersDropdown positionX={dropdownPositionX} positionY={dropdownPositionY} />} 

            { patientFound && <PatientFound />}  

            { patientsList.length > 0 && <PatientsList />} 
    
     </Box>

    );

};

export default SearchBar;