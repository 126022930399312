import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '../themeContext';

// -- Components 

import Logo from '../components/login/Logo';
import Title from '../components/login/Title';
import Container from '../components/login/Container';
import Footer from '../components/general/navigation/Footer';
import Notification from '../components/general/Notification';
import LoginBackground from '../components/login/LoginBackground';

// Utils 

import clearLocalStorage from '../util/storage/clearLocalStorage';
import clearCookies from '../util/storage/clearCookies';


const Login: React.FC = () => {

  const { isLightMode, toggleTheme } = useTheme();

  useEffect(() => { 

    if (isLightMode) {

      toggleTheme(); 

    }; 

  },[isLightMode]); 

  // -- 

  useEffect(() => { 

    clearLocalStorage();
    clearCookies(); 

  },[]); 

  // -- 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'space-evenly', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: 'white'
      }}
    >

      <LoginBackground />

      <Logo />

      <Title />
    
      <Container />

      <Footer />

      <Notification />

    </Box>
  );
};

export default Login;