import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Components

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';

const Filters: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const filters = useAppSelector(state => state.searchPatient.filters); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 30,  
            marginLeft: 10, 
            marginBottom: 5,
            paddingLeft: '5%',
            fontSize: 15,
            color: '#C2C5C8'
        }}>

        {filters?.map((item, index) => (

             <Box 
                key={index}
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: 3
            }}>

                { item === 'firstName' && <AccountCircleIcon sx={{ color: 'rgb(45, 207, 196)' }} />}
                { item === 'lastName' && <AccountBoxIcon sx={{ color: 'rgb(45, 207, 196)' }} />}
                { item === 'dateOfBirth' && <EventNoteIcon sx={{ color: 'rgb(45, 207, 62)' }} />}
                { item === 'healthCardNumber' && <ContactMailIcon sx={{ color: 'rgb(207, 137, 45)' }} />}
                { item === 'phone' && <LocalPhoneIcon sx={{ color: 'rgb(45, 153, 207)' }} /> }
                { item === 'email' && <EmailIcon sx={{ color: 'rgb(45, 207, 196)' }} />}
                { item === 'address' && <AddHomeIcon sx={{ color: 'rgb(45, 207, 62)' }} /> }
                { item === 'patientId' && <AccountBoxIcon sx={{ color: 'rgb(207, 137, 45)' }} /> }

                <Box sx={{ marginLeft: 1 }}>{t(item)}</Box>
            
            </Box>

        ))}
            
        </Box>

    );

};

export default Filters;