import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Utils 

import getRandomColor from '../../../../util/style/getRandomColor';
import getStatusColor from '../../../../util/style/getStatusColor';

// Routes 

import { getTicketDetails } from '../../../../routes/support/support';

// Components 

import MoreVertIcon from '@mui/icons-material/MoreVert';

type Props = {
    author: string, 
  ticketId: string, 
  status?: string,
  category?: string,
  description?: string, 
  createdAt?: string, 
};


const SupportTicketRow: React.FC<Props> = ({author, ticketId, status, category, description, createdAt }) => {

    const dispatch = useAppDispatch(); 

    const [initials, setInitials] = useState(''); 

    const [firstName, setFirstName] = useState(''); 
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    // -- 
    
    const getAdditionalInfo = async () => { 

        const res = await getTicketDetails(author) as any; 

        if (res.data.status === '200') { 

            const author = res.data.author as any; 

            setFirstName(author.firstName); 
            setLastName(author.lastName); 
            setEmail(author.email); 

        };

    }; 

    // -- 

    const getInitials = () => { 

        const lowerCasedInitials = `${firstName[0]}${lastName[0]}`; 

        setInitials(lowerCasedInitials.toUpperCase()); 

    }; 

    // Initial rendering 

    useEffect(() => { 

        getAdditionalInfo(); 
        
    },[]); 

    // -- 

    useEffect(() => { 

        if (firstName && lastName) { 

            getInitials(); 

        }; 

    },[firstName, lastName]); 


  return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
    }}>
            {/* Initials / User avatar */}

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '3%', 
                marginLeft: '2%',
                textAlign: 'left',
                overflow: 'hidden',
            }}>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly', 
                    width: 30,
                    height: 30, 
                    borderRadius: '50%',
                    backgroundColor: getRandomColor(), 
                    color: 'white'
                }}>
                    {initials}

                </div>
            </div>
            
            {/* Full Name */}

            <div style={{ 
                width: '9%',
                marginLeft: '1%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>{firstName} {lastName}</div>

            {/* Ticket Id */}

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{ticketId}</div>


            {/* Status */}

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>
                <strong style={{ 
                    padding: 5,
                    borderRadius: 7,
                    backgroundColor: getStatusColor(status), 
                }}>
                    {status}
                </strong>
            </div>

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{email}</div>

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{category}</div>

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{createdAt}</div>

            <div style={{ 
                width: '10%', 
                color: '#C2C5C8', 
                textAlign: 'center',
                overflow: 'hidden', 
            }}>
                <MoreVertIcon></MoreVertIcon>
            </div>

    </Box>

  );

};

export default SupportTicketRow;