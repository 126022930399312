import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useTranslation } from 'react-i18next';

import CssBaseline from '@mui/material/CssBaseline';

// Redux 

import menuItems from '../util/navigation/sidebarMenu/support';

// Utils

import { setSideBarMenu } from '../redux/features/general/navigation';

// Components 

import NavBar from '../components/general/navBar/NavBar';
import Sidebar from '../components/general/navigation/Sidebar';
import Notification from '../components/general/Notification';
import Settings from '../components/hacker/navigation/Settings'; 

import Container from '../components/support/Container';

const Hacker: React.FC = () => {

  const dispatch = useAppDispatch(); 
  const { t, i18n } = useTranslation();

  const section = useAppSelector(state => state.navigation.section);

  useEffect(() => { 
    dispatch(setSideBarMenu(menuItems(t))); 
  }, [t]); 

  return (

    <React.Fragment>

        <CssBaseline />

        <Box component="main" sx={{ 
            display: 'flex', 
            height: '100vh', 
            width: '100vw',
            left: 0, 
            overflowX: 'hidden',
            position: 'absolute',
            color: 'white' }}>

          <NavBar>
            <Settings />
          </NavBar>

          <Sidebar />

          <Container />

          <Notification />

        </Box>

    </React.Fragment>

  );

};

export default Hacker;