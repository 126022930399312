import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components 

import Box from '@mui/material/Box';
import Section from './Section';
import SectionRow from './SectionRow';

// Icons 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WcIcon from '@mui/icons-material/Wc';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TollIcon from '@mui/icons-material/Toll';


const PatientDataList: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const patient = useAppSelector(state => state.patient.patient); 
    const emergencyContacts = useAppSelector(state => state.patient.emergencyContacts); 

    return (

    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '2rem',
            maxHeight: '90vh',
            overflow: 'auto',
            alignItems: 'center',
            scrollbarWidth: 'none',
        }}
    >

        <Section title={'patientUniqueId'}>

            <SectionRow title={'patientId'} description={patient?._id} copyEnabled>
                <TollIcon sx={{ color: 'rgb(45, 207, 196)'}}/> 
            </SectionRow>

        </Section>

        <Section title={'patientInformation'}>

            <SectionRow title={'firstName'} description={patient?.firstName}>
                <AccountCircleIcon sx={{ color: 'rgb(45, 207, 196)'}}/> 
            </SectionRow>

            <SectionRow title={'lastName'} description={patient?.lastName}>
                <AccountBoxIcon sx={{ color: 'rgb(207, 137, 45)'}}/> 
            </SectionRow>

            <SectionRow title={'sex'} description={patient?.gender}>
                <WcIcon sx={{ color: 'rgb(188, 45, 207)'}}/> 
            </SectionRow>

            <SectionRow title={'dateOfBirth'} description={moment.utc(patient?.dateOfBirth).format('L')}>
                <EventNoteIcon sx={{ color: 'rgb(45, 207, 62)'}}/> 
            </SectionRow>
        
        </Section>

        <Section title='contactInfo'>

            <SectionRow title={'healthCardNumber'} description={patient?.healthCardNumber}>
                <AccountCircleIcon sx={{ color: 'rgb(207, 137, 45)'}}/> 
            </SectionRow>

            <SectionRow title={'phone'} description={patient?.contactInfo.phone}>
                <LocalPhoneIcon sx={{ color: 'rgb(45, 153, 207)'}}/> 
            </SectionRow>

            <SectionRow title={'address'} description={patient?.contactInfo.address}>
                <AddHomeIcon sx={{ color: 'rgb(45, 207, 62)'}}/> 
            </SectionRow>

            <SectionRow title={'email'} description={patient?.contactInfo.email}>
                <EmailIcon sx={{ color: 'rgb(45, 207, 196)'}}/> 
            </SectionRow>

        </Section>

        { emergencyContacts?.length > 0 &&
        
            <Section title='emergencyContact'>

                {emergencyContacts?.map((item, index) => (

                    <>

                    <SectionRow title={'emergencyContactName'} description={item.name}>
                        <AccountCircleIcon sx={{ color: 'rgb(45, 207, 196)'}}/> 
                    </SectionRow>

                    <SectionRow title={'emergencyContactRelation'} description={item.relation}>
                        <AccountBoxIcon sx={{ color: 'rgb(188, 45, 207)'}}/> 
                    </SectionRow>

                    <SectionRow title={'emergencyContactPhone'} description={item.phone}>
                        <AddHomeIcon sx={{ color: 'rgb(45, 153, 207)'}}/> 
                    </SectionRow>

                    </>
    
                ))}

            </Section>}
    
        </Box>

  );
};

export default PatientDataList;