import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import NavItem from './NavItem';


const Navigation: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    return (

    <Box style={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', 
        width: '100%',
        paddingLeft: '10%',
        marginTop: 50, 
        height: 60, 
        overflowY: 'scroll',
        scrollbarWidth: 'none'
    }}>

        <NavItem title={'Doctors'} section={'doctors'} />

        <NavItem title={'Monitoring'} section={'security'} />

        <NavItem title={'Logs'} section={'logs'} />

    </Box>

  );

};

export default Navigation;