const textFieldStyle = {
    marginLeft: 2,
    fontSize: 15,
    width: '90%',
    height: '100%',
}; 

export default textFieldStyle; 



