import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';

// Routes


// Redux 

import { setFilter, setPatientsList } from '../../../../../../../../redux/features/doctor/Dashboard/patients/searchPatient';

// Components 

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import PatientRow from './PatientRow';
import Avatar from './patientFound/Avatar';
import PatientInfo from './patientFound/PatientInfo';
import AuthSection from './patientFound/AuthSection';


const PatientFound: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const filters = useAppSelector(state => state.searchPatient.filters); 
    const patientsList = useAppSelector(state => state.searchPatient.patientsList); 

    // -- 

    return (
            
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '60%', 
            height: 200, 
            backgroundColor: 'rgb(49,51,56)',
            borderRadius: 5, 
            top: '30%',
            position: 'absolute', 
            fontSize: 12,
        }}>

            <Avatar /> 

            <PatientInfo /> 

            <AuthSection /> 

        </Box>

    );

};

export default PatientFound;