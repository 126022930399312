import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    refreshFileExplorer: boolean,  
    searchQuery: string, 
    searchSection: string,
    mode: string, 
    lastInternalFileIndex: any, 
    currentFolder: any, 
    directory: string,
    unconfirmedSelection: any, 
    selectedFile: any,
    selectedFolder: any, 
    folderData: any[],
    isDropdownOpened: boolean,
    patientsFolder: any, 
    documentsFolder: any, 
    downloadsFolder: any, 
    unconfirmedDraggedFile: any, 
    confirmedDraggedFile: any, 
    mouseInfo: any, 
}

const initialState: InitialState = {
    refreshFileExplorer: false, 
    searchQuery: '', 
    searchSection: 'recent',
    mode: '', 
    lastInternalFileIndex: null, // For the search query
    currentFolder: null, 
    directory: '', 
    unconfirmedSelection: null, 
    selectedFile: null,
    selectedFolder: null, 
    folderData: [],
    isDropdownOpened: false,
    patientsFolder: null, 
    documentsFolder: null, 
    downloadsFolder: null, 
    unconfirmedDraggedFile: null, 
    confirmedDraggedFile: null, 
    mouseInfo: null, // For the dropwdown list 
}

export const fileExplorerSlice = createSlice({
  name: 'fileExplorer',
  initialState,
    reducers: {

    setRefreshFileExplorer: (state, action: PayloadAction<boolean>) => { 
      state.refreshFileExplorer = action.payload; 
    },  
    setSearchQuery: (state, action: PayloadAction<string>) => { 
      state.searchQuery = action.payload; 
    },  
    setSearchSection: (state, action: PayloadAction<string>) => {
        state.searchSection = action.payload
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload
    },
    setLastInternalFileIndex: (state, action: PayloadAction<any>) => {
      state.lastInternalFileIndex = action.payload
    },
    setCurrentFolder: (state, action: PayloadAction<any>) => {
      state.currentFolder = action.payload
      state.searchSection = action.payload._id; 
      state.directory = action.payload ? action.payload.path : ''; 
    },
    setDirectory: (state, action: PayloadAction<string>) => {
        state.directory = action.payload;  
    },
    setUnconfirmedSelection: (state, action: PayloadAction<any>) => {
      state.unconfirmedSelection = action.payload
    },
    selectFile: (state, action: PayloadAction<any>) => {
      state.selectedFile = action.payload
    },
    selectFolder: (state, action: PayloadAction<any>) => {
      state.selectedFolder = action.payload
    },
    setFolderData: (state, action: PayloadAction<any>) => { 
      state.folderData = action.payload
    }, 
    setIsDropdownOpened: (state, action: PayloadAction<boolean>) => { 
      state.isDropdownOpened = action.payload
    }, 
    setPatientsFolder: (state, action: PayloadAction<any>) => { 
      state.patientsFolder = action.payload
    }, 
    setDocumentsFolder: (state, action: PayloadAction<any>) => { 
      state.documentsFolder = action.payload
    }, 
    setDownloadsFolder: (state, action: PayloadAction<any>) => { 
      state.downloadsFolder = action.payload
    }, 
    setUnconfirmedDraggedFile: (state, action: PayloadAction<any>) => { 
      state.unconfirmedDraggedFile = action.payload
    }, 
    setConfirmedDraggedFile: (state, action: PayloadAction<any>) => { 
      state.confirmedDraggedFile = action.payload
    }, 
    setMouseInfo: (state, action: PayloadAction<any>) => { 
      state.mouseInfo = action.payload
    }, 
    resetFileExplorer: (state) => { 
        
        state.refreshFileExplorer = false; 
        state.searchQuery = ''; 
        state.searchSection = 'recent';
        state.mode = ''; 
        state.lastInternalFileIndex = null; 
        state.currentFolder = null; 
        state.directory = '';
        state.unconfirmedSelection = null; 
        state.selectedFile = null;
        state.selectedFolder = null; 
        state.folderData = [];
        state.isDropdownOpened = false; 
        state.patientsFolder = null; 
        state.documentsFolder = null; 
        state.downloadsFolder = null; 
        state.unconfirmedDraggedFile = null;
        state.confirmedDraggedFile = null; 
        state.mouseInfo = null; 

    } 
  },
});

export const {
    setRefreshFileExplorer, 
    setSearchQuery, 
    setSearchSection,
    setMode, 
    setLastInternalFileIndex, 
    setCurrentFolder, 
    setDirectory,
    setUnconfirmedSelection, 
    selectFile,
    selectFolder, 
    setFolderData,
    setIsDropdownOpened,
    setPatientsFolder, 
    setDocumentsFolder,
    setDownloadsFolder, 
    setUnconfirmedDraggedFile, 
    setConfirmedDraggedFile, 
    setMouseInfo,  
    resetFileExplorer,
} = fileExplorerSlice.actions;

export default fileExplorerSlice.reducer;