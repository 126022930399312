import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../redux/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Routes


// Redux 


// Components 

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import TollIcon from '@mui/icons-material/Toll';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import { requestPatientAccess, verifyMetaPatientRelationship } from '../../../../../../../../../routes/doctor/patients';
import { setPatientIdFromFileExplorer, setRefreshPatientsList } from '../../../../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { resetSearchPatient, setNewlyAttachedPatientId } from '../../../../../../../../../redux/features/doctor/Dashboard/patients/searchPatient';
import { SettingsRemoteRounded } from '@mui/icons-material';
import { setNotificationMessage } from '../../../../../../../../../redux/features/general/notification';

const AuthSection: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const filters = useAppSelector(state => state.searchPatient.filters); 
    const patientsList = useAppSelector(state => state.searchPatient.patientsList); 
    const patientFound = useAppSelector(state => state.searchPatient.patientFound); 

    const [isAccessRequested, setIsAccessRequested] = useState(false); 
    const [timeLeft, setTimeLeft] = useState(0); 

    // -- 

    const handleRequestAccess = async () => { 

        const res = await requestPatientAccess(patientFound._id) as any; 

        if (res.data.status === 200) { 

            setIsAccessRequested(true); 
            setTimeLeft(3); 

            dispatch(setNotificationMessage(t('accessRequested')));

        } else if (res.data.status === 202) { 

            dispatch(setPatientIdFromFileExplorer(res.data.patientId)); 
            dispatch(resetSearchPatient()); 

            setTimeLeft(0); 
    
        }; 

    }; 

    // -- 

    const handleRequestVerification = async () => { 

        const res = await verifyMetaPatientRelationship(patientFound._id) as any; 

        if (res.data.status === 200) { 

            dispatch(setNotificationMessage(t('requestAccepted')));

            dispatch(setNewlyAttachedPatientId({ patientId: res.data.patientId })); 
            dispatch(setRefreshPatientsList(true)); 
            dispatch(resetSearchPatient()); 

            setTimeLeft(0); 

        };

    }; 

    // -- 

    useEffect(()=>{

        let myInterval = setInterval(() => {

            if (timeLeft > 0) {

                setTimeLeft(timeLeft - 1);

            }; 

            if (timeLeft > 1) { 

                handleRequestVerification(); 

                setTimeLeft(4); 

            }; 

            if (timeLeft === 0) {

                if (timeLeft === 0) {
                    clearInterval(myInterval)
                };
            }; 

        }, 1000); 

        return ()=> {
            clearInterval(myInterval);
        };

    },[timeLeft]);; 

    // -- 

    return (
            
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            width: '40%', 
            height: '100%', 
            borderRadius: 5, 
            fontSize: 12,
        }}>

            <Box
                 sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    textAlign: 'center',
                    width: '90%', 
                    height: '100%',  
                    color: '#C2C5C8'
                }}
            >

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        textAlign: 'center',
                        color: '#C2C5C8'
                    }}
                >{t('patientRequestInfo')}</Box>


                { isAccessRequested ? 
                    <Button 
                        disabled
                        sx={{ 
                            width: 200, 
                            textTransform: 'none', 
                            paddingLeft: 5, 
                            paddingRight: 5, 
                            backgroundColor: 'rgba(59, 196, 105, 0.5)',
                            '&:hover': { backgroundColor: 'rgba(59, 196, 105, 0.9)' }
                        }}
                        variant="contained">{t('accessRequested')}
                    </Button>:                
                    <Button 
                    onClick={() => handleRequestAccess()}
                    sx={{ 
                        width: 200, 
                        textTransform: 'none', 
                        paddingLeft: 5, 
                        paddingRight: 5, 
                        backgroundColor: 'rgba(59, 196, 105, 0.5)',
                        '&:hover': { backgroundColor: 'rgba(59, 196, 105, 0.9)' }
                    }}
                    variant="contained">{t('requestAccess')}
                </Button>}


            </Box>



        </Box>

    );

};

export default AuthSection;