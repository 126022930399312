import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Components 

import SectionHeader from './SectionHeader';
import FirstName from './FirstName';
import LastName from './LastName';
import Email from './Email';
import SubmitButton from './SubmitButton';
import Access from './Access';

const CreateHacker: React.FC = () => {

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '90%',
        marginTop: '10vh', 
        width: '80vw',
        marginLeft: '5vw',
        overflowY: 'scroll',
        scrollbarWidth: 'none'
    }}>

        <SectionHeader title='Create a Hacker Account' />

        <FirstName />

        <LastName /> 

        <Access /> 

        <Email />

        <SubmitButton /> 

    </div>

  );

};

export default CreateHacker;