import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';

// Routes


// Redux 

import { resetSearchPatient, setFilter, setIsFiltersDropdownOpened, setPatientFound, setPatientsList } from '../../../../../../../../redux/features/doctor/Dashboard/patients/searchPatient';

// Components 

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import PatientRow from './PatientRow';
import { verifyMetaPatientRelationship } from '../../../../../../../../routes/doctor/patients';
import { setPatientIdFromFileExplorer, setRefreshPatientsList } from '../../../../../../../../redux/features/doctor/Dashboard/patients/patient';


const PatientsList: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const filters = useAppSelector(state => state.searchPatient.filters); 
    const patientsList = useAppSelector(state => state.searchPatient.patientsList); 

    // -- 

    const handlePatientSelection = async (patientId: any) => { 

        const res = await verifyMetaPatientRelationship(patientId) as any; 

        if (res.data.status === 200) { 

            dispatch(setRefreshPatientsList(true)); 
            dispatch(setPatientIdFromFileExplorer(res.data.patientId)); 
            dispatch(resetSearchPatient()); 

        } else if (res.data.status === 202) { 

            dispatch(setPatientFound(res.data.patient)); 
            dispatch(setPatientsList([])); 
            dispatch(setIsFiltersDropdownOpened(false)); 

        }; 

    }; 

    // -- 

    return (
            
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column-reverse',
            alignItems: 'center', 
            width: '60%', 
            backgroundColor: 'rgb(43,45,49)',
            borderRadius: 5, 
            top: '25%',
            position: 'absolute', 
            fontSize: 12,
        }}>

            { patientsList?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none' }}>

                <List dense disablePadding>

                {patientsList?.map((item, index) => (

                    <ListItem onClick={() => handlePatientSelection(item._id)} button key={index} disablePadding>

                        <PatientRow fullName={`${item.firstName} ${item.lastName}`} patientId={item._id} />

                    </ListItem>

                ))}

                </List>

            </Box>}

            { patientsList?.length === 0 &&         
          
                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        height: 40,
                        color: '#C2C5C8'
                    }}>
                        <Box sx={{ marginLeft: 2 }}>No Patients Detected.</Box>

                </Box>}
                    
        </Box>

    );

};

export default PatientsList;